import { Components, Theme } from '@mui/material';

import { RadioOffIcon, RadioOnIcon } from '@shared/ui/icons';

export const MuiRadio: Components<Omit<Theme, 'components'>>['MuiRadio'] = {
    defaultProps: {
        checkedIcon: RadioOnIcon(),
        icon: RadioOffIcon(),
    },
    styleOverrides: {
        root: ({ theme }) => ({
            minWidth: '42px',
            '&.Mui-checked': {
                color: theme.palette.icon.primary,
            },
        }),
    },
};
