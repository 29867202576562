import { useCallback, useEffect, useState } from 'react';

import {
    TFormDelivery,
    TFormDeliveryType,
    TFormState,
    TProductFilters,
} from '@entities/products/types';
import { TFormProvider } from '@entities/providers/types';
import { TFormData } from '@features/form-filters/form-filters';
import { ProductInfo } from '@shared/api/axios-client';
import { useYandexReachGoal } from '@shared/hooks';

import { CatalogFiltersWidget } from './catalog-filters-widget';

type TProps = {
    products: ProductInfo[];
    providers: TFormProvider[];
    onFilterChange: (filters?: TProductFilters) => void;
};

const formDeliveryDate: TFormDelivery[] = [
    { id: 'any', label: 'Любой' },
    { id: '1', label: '1 день' },
    { id: '3', label: 'До 3 дней' },
    { id: '7', label: 'До 7 дней' },
];

const formDeliveryType: TFormDeliveryType[] = [
    { value: 'any', label: 'любой тип' },
    { value: 'pickup', label: 'самовывоз' },
    { value: 'delivery', label: 'доставка' },
];

const formState: TFormState[] = [
    { value: 'any', label: 'любое' },
    { value: 'new', label: 'новое' },
    { value: 'used', label: 'б/у' },
];

const getIsUsed = (value?: TFormState) => {
    switch (value?.value) {
        case 'new':
            return false;
        case 'used':
            return true;
        default:
            return undefined;
    }
};

const getIsStock = (value?: TFormDeliveryType) => {
    switch (value?.value) {
        case 'delivery':
            return false;
        case 'pickup':
            return true;
        default:
            return undefined;
    }
};

type TPrice = {
    min: number;
    max: number;
};

export const CatalogFiltersWidgetConnector = ({
    products,
    providers,
    onFilterChange,
}: TProps) => {
    const fireProductEvent = useYandexReachGoal();
    const [initPrice, setInitPrice] = useState<TPrice | undefined>(undefined);
    const handleFilterChange = useCallback(
        (data: TFormData) => {
            const filters: TProductFilters = [
                {
                    attribute: 'DeliveryDate',
                    value: data.deliveryDate?.id,
                },
                {
                    attribute: 'IsAvailable',
                    value: getIsStock(data.deliveryType),
                },
                {
                    attribute: 'IsUsed',
                    value: getIsUsed(data.state),
                },
                {
                    attribute: 'MaxPrice',
                    value: data.maxPrice,
                },
                {
                    attribute: 'MinPrice',
                    value: data.minPrice,
                },
                {
                    attribute: 'ProviderId',
                    value: data.provider?.map(item => item.id),
                },
            ];
            fireProductEvent('filterProducts', filters);
            onFilterChange(filters);
        },
        [fireProductEvent, onFilterChange],
    );

    useEffect(() => {
        !initPrice &&
            products.length > 0 &&
            setInitPrice(
                products.reduce(
                    (acc, product) => {
                        let newPrice = acc;
                        if (product.warehouse.price < acc.min) {
                            newPrice.min = Math.floor(product.warehouse.price);
                        }
                        if (product.warehouse.price > acc.max) {
                            newPrice.max = Math.ceil(product.warehouse.price);
                        }
                        return newPrice;
                    },
                    { min: Infinity, max: 0 },
                ),
            );
    }, [initPrice, products]);
    return (
        <CatalogFiltersWidget
            formDelivery={formDeliveryDate}
            formProviders={providers}
            formDeliveryType={formDeliveryType}
            formState={formState}
            onFilterChange={handleFilterChange}
            initValues={{
                deliveryDate: formDeliveryDate[0],
                provider: [],
            }}
            priceLimit={initPrice || { min: 0, max: Infinity }}
        />
    );
};
