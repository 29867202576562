import { useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { UnknownBusinessError, productApi } from '@shared/api';
import {
    ProductsApiGetV1SearchBrandsRequest,
    SearchBrandsResponse,
} from '@shared/api/axios-client';

import { productsKeys } from '../query-keys';

type TBusinessError = UnknownBusinessError;

type TArgs = {
    body: ProductsApiGetV1SearchBrandsRequest;
    onSuccess?: (data: AxiosResponse<SearchBrandsResponse, any>) => void;
    onError?: (businessError: TBusinessError) => void;
};

export const useGetBrands = ({ body, onSuccess, onError }: TArgs) => {
    return useQuery(
        productsKeys.brands(body).queryKey,
        () => {
            return productApi.getV1SearchBrands(body, {
                headers: {
                    Authorization: 'Bearer',
                },
            });
        },
        {
            onSuccess: data => {
                // TODO (@OKA) SA BE - вернуть эту обработку
                // if (data.data.providersError?.length) {
                //     queryClient.invalidateQueries(userKeys.providers.queryKey);
                // }
                onSuccess?.(data);
            },
            onError: ({ response }: AxiosError<TBusinessError>) => {
                if (!onError) {
                    return;
                }
                if (response?.data.code && response.data.message) {
                    onError(response.data);
                } else {
                    onError({
                        code: 'unknown_code',
                        message: 'что-то пошло не так',
                        response,
                    });
                }
            },
        },
    );
};
