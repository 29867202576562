export const ExclamationMarkIcon = () => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 273 273"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M136.5 204.174C139.149 204.174 141.363 203.279 143.143 201.489C144.933 199.699 145.827 197.48 145.827 194.831C145.827 192.192 144.933 189.978 143.143 188.188C141.363 186.398 139.149 185.504 136.5 185.504C133.851 185.504 131.637 186.398 129.857 188.188C128.067 189.978 127.173 192.192 127.173 194.831C127.173 197.48 128.067 199.699 129.857 201.489C131.637 203.279 133.851 204.174 136.5 204.174ZM128.917 154.002H144.083V63.0023H128.917V154.002ZM136.546 273C117.678 273 99.9332 269.421 83.3105 262.262C66.6979 255.093 52.2441 245.366 39.949 233.081C27.6539 220.806 17.9219 206.368 10.7532 189.765C3.58439 173.163 0 155.423 0 136.546C0 117.678 3.57933 99.9332 10.738 83.3105C17.9068 66.6979 27.6337 52.2441 39.9187 39.949C52.1936 27.6539 66.6322 17.9219 83.2347 10.7532C99.8371 3.58439 117.577 0 136.454 0C155.322 0 173.067 3.57933 189.689 10.738C206.302 17.9068 220.756 27.6337 233.051 39.9187C245.346 52.1936 255.078 66.6322 262.247 83.2347C269.416 99.8371 273 117.577 273 136.454C273 155.322 269.421 173.067 262.262 189.689C255.093 206.302 245.366 220.756 233.081 233.051C220.806 245.346 206.368 255.078 189.765 262.247C173.163 269.416 155.423 273 136.546 273ZM136.5 257.833C170.372 257.833 199.062 246.079 222.571 222.571C246.079 199.062 257.833 170.372 257.833 136.5C257.833 102.628 246.079 73.9375 222.571 50.4292C199.062 26.9208 170.372 15.1667 136.5 15.1667C102.628 15.1667 73.9375 26.9208 50.4292 50.4292C26.9208 73.9375 15.1667 102.628 15.1667 136.5C15.1667 170.372 26.9208 199.062 50.4292 222.571C73.9375 246.079 102.628 257.833 136.5 257.833Z"
                fill="currentColor"
            />
        </svg>
    );
};
