import { useUnit } from 'effector-react';
import { useSnackbar } from 'notistack';
import { useCallback, useMemo, useState } from 'react';

import { $userInfo } from '@entities/auth/model/auth-domain';
import {
    useCartProductEcommerce,
    useChangeCartAmount,
    useDeleteCart,
    useDeleteCartItem,
    useGetCart,
} from '@entities/cart/model';
import { updateCartAmount } from '@entities/cart/model/cart-domain';
import { useDeleteCartGroup } from '@entities/cart/model/hooks/use-delete-cart-group';
import { TCart, TCartChangeItem, TCartRemoveItem } from '@entities/cart/types';
import { CartDialog } from '@entities/cart/ui/cart-dialog';
import { ENV } from '@shared/config/constants';
import {
    useNavigateExternal,
    useStateModal,
    useYandexReachGoal,
} from '@shared/hooks';
import { SlideModal } from '@shared/ui/molecules/slide-modal';

import { CartWidget } from './cart-widget';
import { InstantInfoModal } from './ui/instant-info-modal';
import { InstantOrderModal } from './ui/instant-order-modal';
import { RemoveModal } from './ui/remove-modal';
import { mapCart } from './utils';

type TProps = {
    isOpen: boolean;
    onClose: () => void;
};

export const CartWidgetConnector = ({ isOpen, onClose }: TProps) => {
    const userInfo = useUnit($userInfo);
    const navigateExt = useNavigateExternal();
    const fireProductEvent = useYandexReachGoal();
    const userData = useUnit($userInfo);
    const { handelChangeQuantity: changeCartAmount } = useChangeCartAmount();
    const [modalCart, setModalCart] = useState<TCart>();
    const [instantCart, setInstantCart] = useState<TCart>();
    const { purchaseAction } = useCartProductEcommerce();
    const {
        isOpen: isCartInfoOpen,
        open: openCartInfo,
        close: closeCartInfo,
    } = useStateModal(false);
    const { enqueueSnackbar } = useSnackbar();
    const {
        isOpen: modalDelete,
        open: openRemoveCart,
        close: closeRemoveModal,
    } = useStateModal();
    const onUnknownPostError = () =>
        enqueueSnackbar('что-то пошло не так', {
            variant: 'error',
            description: 'попробуйте повторить запрос',
        });
    const { data } = useGetCart({
        onSuccess: data => updateCartAmount(data.data.totalQuantity),
        onError: () =>
            enqueueSnackbar('что-то пошло не так', {
                variant: 'error',
                description: 'обновите страницу или попробуйте позже',
            }),
    });
    const { deleteCartItem } = useDeleteCartItem({
        onError: onUnknownPostError,
    });
    const { deleteCart } = useDeleteCart({
        onError: onUnknownPostError,
    });
    const { deleteCartGroup } = useDeleteCartGroup({
        onSuccess: () => {
            setModalCart(undefined);
        },
        onError: onUnknownPostError,
    });
    const handleRemoveGroup = useCallback(
        (providerId: string) => {
            deleteCartGroup({ providerId: providerId });
        },
        [deleteCartGroup],
    );
    const handleChangeCart = useCallback(
        (cartItem: TCartChangeItem) => {
            changeCartAmount(
                cartItem,
                String(userData?.clientId),
                data?.data.cart,
            );
        },
        [changeCartAmount, data?.data.cart, userData?.clientId],
    );
    const handleRemoveItem = useCallback(
        (cartItem: TCartRemoveItem) => {
            deleteCartItem(cartItem);
        },
        [deleteCartItem],
    );
    const handleExportGroup = useCallback(
        (cart: TCart) => {
            if (!instantCart && cart.instantOrderAvailable) {
                setInstantCart(cart);
                return;
            }
            instantCart && setInstantCart(undefined);
            purchaseAction(
                cart.items.map(item => ({
                    id: item.id,
                    name: item.title,
                    category: item.article,
                    list: cart.providerName,
                    price: Math.ceil(item.price),
                    quantity: item.amount,
                    brand: String(userData?.clientId),
                })),
                cart.id + cart.providerName + String(userData?.clientId),
            );
            fireProductEvent('openSupplierMarket', {
                supplier: cart.providerId,
                cart_id: data?.data.id,
                total: cart.totalPrice,
            });
            const userCart = {
                clid: userInfo?.clientId,
                cart: cart.items.map(item => ({
                    article: item.article,
                    brand: item.brand,
                    name: item.title,
                    providerId: item.providerId,
                    providerName: item.providerName,
                    inStock: item.stock,
                    shipmentDate: item.deliveryDate,
                    pricePerItem: item.price,
                    itemInCart: item.amount,
                    totalPrice: item.amount * item.price,
                })),
            };
            navigateExt(
                `${ENV.yandexExportCartLink}${JSON.stringify(userCart)}`,
            );
            setModalCart(cart);
        },
        [
            data?.data.id,
            fireProductEvent,
            instantCart,
            navigateExt,
            purchaseAction,
            userData?.clientId,
            userInfo?.clientId,
        ],
    );
    const handleFinance = useCallback(() => {
        navigateExt(ENV.yandexFinanceLink);
    }, [navigateExt]);
    const handleRemoveCart = useCallback(() => {
        deleteCart();
        closeRemoveModal();
    }, [closeRemoveModal, deleteCart]);
    const cart = useMemo(
        () =>
            mapCart(
                handleRemoveGroup,
                handleChangeCart,
                handleRemoveItem,
                handleExportGroup,
                data?.data,
            ),
        [
            data?.data,
            handleChangeCart,
            handleExportGroup,
            handleRemoveGroup,
            handleRemoveItem,
        ],
    );

    const handleSuccessExport = () => {
        if (!modalCart) {
            return;
        }
        fireProductEvent('finishSupplierMarket', {
            supplier: modalCart.providerId,
            cart_id: data?.data.id,
            total: modalCart.totalPrice,
        });
        deleteCartGroup({ providerId: modalCart.providerId });
    };

    const handleFailedExport = () => {
        fireProductEvent('unsuccessSupplierMarket', {
            supplier: modalCart?.providerId,
            cart_id: data?.data.id,
            total: modalCart?.totalPrice,
        });
        setModalCart(undefined);
    };

    if (!data) {
        return null;
    }
    return (
        <>
            <CartWidget
                items={cart}
                onClose={onClose}
                isOpen={isOpen}
                cartAmount={data?.data.totalQuantity}
                totalPrice={data?.data.totalAmount}
                onRemoveCart={openRemoveCart}
                onCartInfoClick={openCartInfo}
                onFinance={handleFinance}
            />
            <SlideModal open={Boolean(modalCart)}>
                <CartDialog
                    cartItem={modalCart}
                    onPrimaryButtonClick={handleSuccessExport}
                    onSecondaryButtonClick={handleFailedExport}
                />
            </SlideModal>

            <RemoveModal
                isOpen={modalDelete}
                onClose={closeRemoveModal}
                onRemove={handleRemoveCart}
            />
            <InstantInfoModal isOpen={isCartInfoOpen} onClose={closeCartInfo} />
            <InstantOrderModal
                isOpen={Boolean(instantCart)}
                onClose={() => setInstantCart(undefined)}
                onOrder={() => instantCart && handleExportGroup(instantCart)}
            />
        </>
    );
};
