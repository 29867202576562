import { Components, Theme } from '@mui/material';

export const MuiInputLabel: Components<
    Omit<Theme, 'components'>
>['MuiInputLabel'] = {
    styleOverrides: {
        root: ({ theme }) => ({
            fontFamily: theme.typography.inputValue1.fontFamily,
            fontWeight: theme.typography.inputValue1.fontWeight,
            lineHeight: theme.typography.inputValue1.lineHeight,
            color: theme.palette.typography.secondary,
            transition: 'transform .5s, font-size .5s, opacity .5s',
            transform: 'translate(0, 0px) scale(1)',
            fontSize: theme.typography.caption2.fontSize,
            '&:not(.MuiFormLabel-filled)': {
                fontSize: theme.typography.inputValue1.fontSize,
                transform: 'translate(0, 35px)',
            },
            '&.Mui-disabled': {
                color: theme.palette.text.disabled,
            },
            '&.Mui-focused': {
                fontSize: theme.typography.caption2.fontSize,
                color: theme.palette.typography.secondary,
                transform: 'translate(0, 0px)',
            },
            '&+ .MuiInput-root': {
                marginTop: '30px',
            },
            '&.Mui-error': {
                color: theme.palette.typography.negative,
            },
            '&.MuiInputLabel-sizeSmall': {
                fontFamily: theme.typography.caption1.fontFamily,
                fontWeight: theme.typography.caption1.fontWeight,
                lineHeight: theme.typography.caption1.lineHeight,
                color: theme.palette.typography.primary,
            },
            '&.MuiInputLabel-sizeSmall&+ .MuiInput-root': {
                marginTop: '12px',
            },
        }),
    },
};
