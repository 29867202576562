export const RadioOffIcon = () => {
    return (
        <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7 2C6.34339 2 5.69321 2.12933 5.08658 2.3806C4.47995 2.63188 3.92876 3.00017 3.46447 3.46447C3.00017 3.92876 2.63188 4.47995 2.3806 5.08658C2.12933 5.69321 2 6.34339 2 7C2 8.32608 2.52678 9.59785 3.46447 10.5355C4.40215 11.4732 5.67392 12 7 12C8.32608 12 9.59785 11.4732 10.5355 10.5355C11.4732 9.59785 12 8.32608 12 7C12 6.34339 11.8707 5.69321 11.6194 5.08658C11.3681 4.47996 10.9998 3.92876 10.5355 3.46447C10.0712 3.00017 9.52005 2.63188 8.91342 2.3806C8.30679 2.12933 7.65661 2 7 2ZM4.32122 0.532843C5.1705 0.18106 6.08075 0 7 0C7.91925 0 8.8295 0.18106 9.67878 0.532843C10.5281 0.884626 11.2997 1.40024 11.9497 2.05025C12.5998 2.70026 13.1154 3.47194 13.4672 4.32122C13.8189 5.1705 14 6.08075 14 7C14 8.85652 13.2625 10.637 11.9497 11.9497C10.637 13.2625 8.85652 14 7 14C5.14348 14 3.36301 13.2625 2.05025 11.9497C0.737498 10.637 0 8.85652 0 7C0 6.08075 0.18106 5.1705 0.532843 4.32122C0.884626 3.47194 1.40024 2.70026 2.05025 2.05025C2.70026 1.40024 3.47194 0.884626 4.32122 0.532843Z"
                fill="currentColor"
            />
        </svg>
    );
};
