import { ProductInfo } from '@shared/api/axios-client';
import { TEcommerceProduct } from '@shared/hooks/use-yandex-ecommerce';

export const ecommerceProductMap = (
    products: ProductInfo[],
    quantity?: number,
): TEcommerceProduct[] => {
    return products.map(product => ({
        id: product.product.id,
        name: product.product.name,
        brand: product.product.brand,
        category: product.product.article,
        list: product.providers.name,
        price: Math.ceil(product.warehouse.price),
        quantity: quantity,
        variant: String(product.warehouse.used),
    }));
};
