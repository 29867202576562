import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { ReactNode, useCallback, useMemo } from 'react';

import { TAdd } from '@entities/adds/types';
import { AddBlock } from '@entities/adds/ui/add-block';
import { TCartValue } from '@entities/cart/types';
import { TProductTable, TSortableField } from '@entities/products/types';
import { ProductsEmpty } from '@entities/products/ui/products-empty';
import { ProductTable } from '@entities/products/ui/products-table';
import { ProviderErrorAlert } from '@entities/products/ui/provider-error-alert';
import { FeatureToggle } from '@features/feature-toggle';
import { useStateModal } from '@shared/hooks';
import { selectors } from '@shared/test/selectors';
import { Conditional } from '@shared/ui/atoms/conditional';

import { CatalogPageSkeleton } from './ui/catalog-page-skeleton';
import { EmptyTableProducts } from './ui/empty-table-products';
import { ProductControllers } from './ui/product-controllers';
import { catalogTableHeaders } from './utils/data';

type TProps = {
    adds: TAdd[];
    totalCount: number;
    pageLimit: number;
    article: string;
    products: TProductTable[];
    priorProducts: TProductTable[];
    cartValue: TCartValue[];
    isLoading: boolean;
    sort: TSortableField;
    isFiltered: boolean;
    isProducts: boolean;
    providersError: string[];
    FilterWidgetComponent: ReactNode;
    favoriteHandlers: {
        onAddFavorite: (productId: string) => void;
        onRemoveFavorite: () => void;
    };
    isAddCartLoading?: boolean;
    isFavorite?: boolean;
    onAddClick: (id: string) => void;
    onHelpClick: () => void;
    onChangeProvider?: () => void;
    onAddProvider?: () => void;
    onPropositionProvider: () => void;
    onSortChange: (sort: TSortableField) => void;
    onQuantityChange: (value: TCartValue) => void;
    onBackClick: () => void;
};

const isFavoriteEnabled = FeatureToggle.hasFeatureFlag('favorite');
const isAddEnabled = FeatureToggle.hasFeatureFlag('adds');

export const CatalogPage = ({
    adds,
    totalCount,
    pageLimit,
    article,
    products,
    priorProducts,
    cartValue,
    isLoading,
    sort,
    isFiltered,
    isProducts,
    providersError,
    FilterWidgetComponent,
    favoriteHandlers,
    isAddCartLoading,
    isFavorite,
    onAddClick,
    onHelpClick,
    onChangeProvider,
    onAddProvider,
    onPropositionProvider,
    onSortChange,
    onQuantityChange,
    onBackClick,
}: TProps) => {
    const { isOpen, close } = useStateModal(Boolean(providersError.length));
    const Adds = useMemo(
        () =>
            isAddEnabled
                ? adds.map(item => (
                      <Box paddingY={{ xs: '2px', md: '40px' }}>
                          <AddBlock
                              border="square"
                              {...item}
                              onClick={() => onAddClick(item.id)}
                          />
                      </Box>
                  ))
                : [],
        [adds, onAddClick],
    );
    const favoriteVariant = {
        active: {
            handler: favoriteHandlers.onRemoveFavorite,
            icon: <FavoriteIcon />,
            title: 'В избранном',
        },
        nonActive: {
            handler: () =>
                favoriteHandlers.onAddFavorite(
                    products.at(0)?.id || priorProducts.at(0)?.id || '',
                ),
            icon: <FavoriteBorderIcon />,
            title: 'Добавить в избранное',
        },
    };
    const favorite = favoriteVariant[isFavorite ? 'active' : 'nonActive'];
    const handleHeaderClick = useCallback(
        (attribute: TSortableField['sortBy']) => {
            if (sort.sortBy !== attribute) {
                return onSortChange({
                    sortBy: attribute,
                    sortOperator: 'Asc',
                });
            }
            if (sort.sortOperator === 'Asc') {
                return onSortChange({
                    sortBy: attribute,
                    sortOperator: 'Desc',
                });
            }
            return onSortChange({
                sortBy: attribute,
                sortOperator: 'Asc',
            });
        },
        [onSortChange, sort],
    );
    const handleChange = (
        newQuantity: number,
        position: number,
        productId?: string,
        providerId?: string,
        warehouseId?: string,
        brand?: string,
    ) => {
        productId &&
            providerId &&
            warehouseId &&
            onQuantityChange({
                productId,
                providerId,
                warehouseId,
                quantity: newQuantity,
                position: position,
                brand: brand,
            });
    };
    if (!isProducts && !isLoading && !isFiltered) {
        return (
            <Stack padding="60px 40px 40px 40px" gap="40px">
                <ProductsEmpty
                    onAddProvider={onAddProvider}
                    onPropositionProvider={onPropositionProvider}
                    onHelpClick={onHelpClick}
                    onBackClick={onBackClick}
                    InfoComponent={
                        isOpen && onChangeProvider ? (
                            <ProviderErrorAlert
                                onClose={close}
                                onChangeProvider={onChangeProvider}
                                providersError={providersError}
                            />
                        ) : null
                    }
                />
                <AddBlock {...adds[0]} onClick={() => onAddClick(adds[0].id)} />
            </Stack>
        );
    }
    return (
        <Stack direction="column">
            <Stack
                direction="column"
                padding={{ xs: '8px', md: '60px 40px 0 40px' }}
                gap="60px"
                sx={theme => ({
                    background: theme.palette.surface.secondary,
                })}>
                <Stack
                    direction="column"
                    sx={theme => ({
                        borderBottom: `solid 1px ${theme.palette.border.border5}`,
                    })}
                    paddingBottom="30px"
                    gap="30px">
                    <Stack direction="column" gap="10px">
                        <Stack
                            direction="row"
                            justifyContent={{
                                xs: 'center',
                                md: 'space-between',
                            }}
                            flexWrap="wrap"
                            gap="8px">
                            <Typography variant="h2">арт. {article}</Typography>
                            <Box>
                                <Button
                                    data-testid={selectors.catalogHelpButton}
                                    variant="contained"
                                    color="buttonPrimary"
                                    onClick={onHelpClick}>
                                    помощь в подборе деталей
                                </Button>
                            </Box>
                        </Stack>
                        <Conditional condition={isFavoriteEnabled}>
                            <Box>
                                <Button
                                    variant="text"
                                    onClick={favorite.handler}
                                    startIcon={favorite.icon}
                                    color="buttonPrimary"
                                    size="small">
                                    {favorite.title}
                                </Button>
                            </Box>
                        </Conditional>
                    </Stack>

                    <Box minHeight="60px">
                        <Conditional condition={isOpen}>
                            {onChangeProvider && (
                                <ProviderErrorAlert
                                    onClose={close}
                                    onChangeProvider={onChangeProvider}
                                    providersError={providersError}
                                />
                            )}
                        </Conditional>
                    </Box>
                </Stack>
            </Stack>
            <Grid
                container
                direction="row"
                paddingTop="10px"
                paddingBottom="40px"
                sx={theme => ({
                    background: theme.palette.surface.secondary,
                })}>
                <Grid item xs={12} md={2}>
                    <Stack
                        direction="column"
                        gap="14px"
                        paddingLeft="40px"
                        paddingTop="65px"
                        height="100%"
                        justifyContent="center">
                        <Typography variant="caption1">
                            Найдено предложений
                        </Typography>
                        <Typography variant="h2">{totalCount}</Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12} md={10}>
                    {FilterWidgetComponent}
                </Grid>
            </Grid>
            <Box padding="40px">
                {isLoading ? (
                    <CatalogPageSkeleton />
                ) : (
                    <Stack alignItems="center">
                        <ProductTable
                            page={1}
                            pageLimit={pageLimit}
                            adds={Adds}
                            tableHeader={catalogTableHeaders}
                            sortableField={sort}
                            products={products}
                            priorProducts={priorProducts}
                            EmptyComponent={<EmptyTableProducts />}
                            onHeaderClick={handleHeaderClick}
                            renderControl={({
                                position,
                                providerId,
                                productId,
                                warehouseId,
                                brand,
                            }) => {
                                const count = cartValue.find(
                                    item =>
                                        item.productId === productId &&
                                        item.warehouseId === warehouseId,
                                );
                                const isAllData =
                                    providerId && productId && warehouseId;
                                return isAllData ? (
                                    <ProductControllers
                                        cartQuantity={count?.quantity}
                                        cartStock={count?.stock}
                                        isDisabled={isAddCartLoading}
                                        onCounter={newCount =>
                                            handleChange(
                                                newCount,
                                                position,
                                                productId,
                                                providerId,
                                                warehouseId,
                                                brand,
                                            )
                                        }
                                    />
                                ) : null;
                            }}
                        />
                    </Stack>
                )}
            </Box>
        </Stack>
    );
};
