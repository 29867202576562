/* eslint-disable boundaries/element-types */
//TODO (@OKA) refactor keys
import { useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { FeatureToggle } from '@features/feature-toggle';
import { UnknownBusinessError, priceApi } from '@shared/api';
import { PriceListUrlResponse } from '@shared/api/axios-client';

import { productsKeys } from '../query-keys';

type TBusinessError = UnknownBusinessError;

type TArgs = {
    onSuccess?: (data: AxiosResponse<PriceListUrlResponse, any>) => void;
    onError?: (businessError: TBusinessError) => void;
};

const IS_PRICE_LIST_ENABLED = FeatureToggle.hasFeatureFlag('priceList');

export const useGetPriceList = (props?: TArgs) => {
    return useQuery(
        productsKeys.priceList.queryKey,
        () => {
            return priceApi.getV1PriceListUrl({
                headers: {
                    Authorization: 'Bearer',
                },
            });
        },
        {
            enabled: IS_PRICE_LIST_ENABLED,
            onSuccess: data => {
                props?.onSuccess?.(data);
            },
            onError: ({ response }: AxiosError<TBusinessError>) => {
                if (!props?.onError) {
                    return;
                }
                if (response?.data.code && response.data.message) {
                    props?.onError(response.data);
                } else {
                    props?.onError({
                        code: 'unknown_code',
                        message: 'что-то пошло не так',
                        response,
                    });
                }
            },
        },
    );
};
