import axios, { AxiosResponse } from 'axios';

import {
    AdvertisingApi,
    AuthorizationApi,
    CartApi,
    NewsApi,
    PriceApi,
    ProductsApi,
    ProvidersApi,
    UserApi,
} from './axios-client';
import { apiConfig } from './configurations';

export { queryClient } from './query-client';
export const apiInstance = axios.create();

export const authApi = new AuthorizationApi(apiConfig, undefined, apiInstance);
export const productApi = new ProductsApi(apiConfig, undefined, apiInstance);
export const newsApi = new NewsApi(apiConfig, undefined, apiInstance);
export const cartApi = new CartApi(apiConfig, undefined, apiInstance);
export const providersApi = new ProvidersApi(apiConfig, undefined, apiInstance);
export const userApi = new UserApi(apiConfig, undefined, apiInstance);
export const addsApi = new AdvertisingApi(apiConfig, undefined, apiInstance);
export const priceApi = new PriceApi(apiConfig, undefined, apiInstance);

export type OptionalAuthorizationHeader = string | undefined;

export type UnknownBusinessError = {
    code: 'unknown_code';
    message: 'что-то пошло не так';
    response?: AxiosResponse<unknown>;
};
