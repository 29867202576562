import { ReactElement, useEffect } from 'react';

import { useGetSearchHistory } from '@entities/user/model/hooks';
import { setSearchHistory } from '@entities/user/model/user-domain';

type TProps = {
    children: ReactElement | null;
};

export const UserSearchHistoryGuard = ({ children }: TProps) => {
    const { data } = useGetSearchHistory();
    useEffect(() => {
        setSearchHistory(data?.data.articles || []);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return children;
};
