import { TFeatureFlags } from './types';

export const defaultFlags: TFeatureFlags = {
    favorite: true,
    providers: false,
    newses: true,
    adds: true,
    yandexProducts: true,
    priceList: true,
};
