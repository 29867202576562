import { useCallback, useState } from 'react';

import { TCartValue } from '@entities/cart/types';
import { CartProductGroup } from '@shared/api/axios-client';
import { useYandexEcommerce } from '@shared/hooks';
import { TEcommerceProduct } from '@shared/hooks/use-yandex-ecommerce';

export const useCartEcommerce = () => {
    const { addAction, removeAction, purchaseAction } = useYandexEcommerce();
    const [lastCartItem, setLastCartItem] = useState<TCartValue>();
    const [timer, setTimer] = useState<NodeJS.Timeout>();
    const handleAddSameAction = useCallback(
        (
            itemToAdd: TCartValue,
            clid: string,
            cart?: CartProductGroup['products'],
        ) => {
            if (!itemToAdd) {
                return;
            }
            const cartItem = cart?.find(
                item =>
                    item.product.id === itemToAdd.productId &&
                    item.warehouse.id === itemToAdd.warehouseId,
            );

            const item: TEcommerceProduct = {
                id: itemToAdd.productId,
                name: itemToAdd.productId,
                quantity: itemToAdd.quantity,
                position: itemToAdd.position,
                brand: cartItem?.product?.brand || itemToAdd.brand,
                category: clid,
            };
            if (!cartItem?.warehouse.quantity) {
                return addAction(item);
            }
            if (cartItem?.warehouse.quantity < itemToAdd.quantity) {
                return addAction(item);
            }
            return removeAction(item);
        },
        [addAction, removeAction],
    );
    const changeCartActions = useCallback(
        (
            currentCartItem: TCartValue,
            clid: string,
            cart?: CartProductGroup['products'],
        ) => {
            const isSame =
                lastCartItem?.productId === currentCartItem.productId &&
                lastCartItem.providerId === currentCartItem.providerId &&
                lastCartItem.warehouseId === currentCartItem.warehouseId;
            if (isSame) {
                clearTimeout(timer);
            }
            setLastCartItem(currentCartItem);
            const timeout = setTimeout(() => {
                handleAddSameAction(currentCartItem, clid, cart);
            }, 5000);
            setTimer(timeout);
        },
        [handleAddSameAction, lastCartItem, timer],
    );

    return { changeCartActions, purchaseAction };
};
