import { SvgIconProps } from '@mui/material';

export const InfoOutlinedIcon = (props: SvgIconProps) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 106 106"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path d="M49 49H57V81H49V49Z" fill="currentColor" />
            <path
                d="M57 32C57 34.2091 55.2091 36 53 36C50.7909 36 49 34.2091 49 32C49 29.7909 50.7909 28 53 28C55.2091 28 57 29.7909 57 32Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M53 98C77.8528 98 98 77.8528 98 53C98 28.1472 77.8528 8 53 8C28.1472 8 8 28.1472 8 53C8 77.8528 28.1472 98 53 98ZM53 106C82.2711 106 106 82.2711 106 53C106 23.7289 82.2711 0 53 0C23.7289 0 0 23.7289 0 53C0 82.2711 23.7289 106 53 106Z"
                fill="currentColor"
            />
        </svg>
    );
};
