import { createDomain, createStore } from 'effector';

import { TFormProvider } from '../types';

export const providerDomain = createDomain('provider');
export const resetProviderDomainStores = providerDomain.createEvent();

providerDomain.onCreateStore(store => {
    store.reset(resetProviderDomainStores);
});

type TProviderFilters = {
    brand: string;
    article: string;
    providers: TFormProvider[];
};

export const updateProvidersFilters =
    providerDomain.createEvent<TProviderFilters>();

export const $providersFilters = createStore<TProviderFilters>({
    brand: '',
    article: '',
    providers: [],
});

$providersFilters.on(updateProvidersFilters, (state, payload) => {
    if (state.article !== payload?.article || state.brand !== payload?.brand) {
        return payload;
    } else {
        return state;
    }
});
