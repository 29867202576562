import { TProductFilters } from '@entities/products/types';
import { AttributeFilter } from '@shared/api/axios-client';

const getDeliveryDate = (value: string) => {
    const date = new Date();
    switch (value) {
        case '1':
            return new Date(date.setDate(date.getDate() + 1))
                .toISOString()
                .split('T')[0];
        case '3':
            return new Date(date.setDate(date.getDate() + 3))
                .toISOString()
                .split('T')[0];
        case '7':
            return new Date(date.setDate(date.getDate() + 7))
                .toISOString()
                .split('T')[0];
        default:
            return new Date().toISOString().split('T')[0];
    }
};

export const mapFilters = (filters?: TProductFilters): AttributeFilter[] => {
    if (!filters) {
        return [];
    }
    const resultFilters: AttributeFilter[] = filters.reduce<AttributeFilter[]>(
        (acc, filter) => {
            if (filter.value !== undefined) {
                switch (filter.attribute) {
                    case 'DeliveryDate':
                        if (filter.value === 'any') {
                            return acc;
                        }
                        return [
                            ...acc,
                            {
                                filterBy: filter.attribute,
                                filterValue: [getDeliveryDate(filter.value)],
                                filterOperator: 'Le' as const,
                            },
                        ];
                    case 'IsAvailable':
                        return [
                            ...acc,
                            {
                                filterBy: filter.attribute,
                                filterValue: [String(filter.value)],
                                filterOperator: filter.value
                                    ? ('true' as const)
                                    : ('false' as const),
                            },
                        ];
                    case 'IsUsed':
                        return [
                            ...acc,
                            {
                                filterBy: filter.attribute,
                                filterValue: [String(filter.value)],
                                filterOperator: filter.value
                                    ? ('true' as const)
                                    : ('false' as const),
                            },
                        ];
                    case 'MaxPrice':
                        return [
                            ...acc,
                            {
                                filterBy: filter.attribute,
                                filterValue: [String(filter.value)],
                                filterOperator: 'Le' as const,
                            },
                        ];
                    case 'MinPrice':
                        return [
                            ...acc,
                            {
                                filterBy: filter.attribute,
                                filterValue: [String(filter.value)],
                                filterOperator: 'Ge' as const,
                            },
                        ];
                    case 'ProviderId':
                        if (!filter.value || filter.value.length < 1) {
                            return acc;
                        }
                        return [
                            ...acc,
                            {
                                filterBy: 'ProviderId' as const,
                                filterValue: filter.value,
                                filterOperator: 'Eq' as const,
                            },
                        ];
                    default:
                        return acc;
                }
            }
            return acc;
        },
        [],
    );

    return resultFilters;
};
