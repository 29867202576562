import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';

import { selectors } from '@shared/test/selectors';
type TProps = {
    title: string;
    imgUrl: string;
    buttonText: string;
    text: string;
    onClick: () => void;
    onClose: () => void;
};

export const NewsCardDetails = ({
    title,
    imgUrl,
    buttonText,
    text,
    onClick,
    onClose,
}: TProps) => {
    return (
        <Stack
            width={{ xs: '100%', md: '620px' }}
            maxWidth="620px"
            maxHeight="90dvh"
            height="100%"
            padding="20px"
            gap="20px"
            borderRadius="32px"
            sx={theme => ({ background: theme.palette.common.white })}>
            <Stack direction="row" justifyContent="space-between">
                <Typography
                    variant="h4"
                    sx={{
                        display: '-webkit-box',
                        '-webkit-line-clamp': '3',
                        '-webkit-box-orient': 'vertical',
                        overflow: 'hidden',
                        'text-overflow': 'ellipsis',
                    }}>
                    {title}
                </Typography>
                <IconButton
                    onClick={onClose}
                    color="buttonPrimary"
                    data-testid={selectors.newsCardDetailsCloseButton}>
                    <CloseIcon />
                </IconButton>
            </Stack>
            <Stack overflow="auto" gap="20px">
                <Box
                    component="img"
                    alt="Изображение банера"
                    src={imgUrl}
                    borderRadius="20px"
                    height="355px"
                    minHeight="355px"
                    sx={{
                        objectFit: 'cover',
                    }}
                />
                <Stack gap="10px">
                    <Typography variant="subtitle1">{title}</Typography>
                    <Typography
                        variant="body2"
                        dangerouslySetInnerHTML={{ __html: text }}
                    />
                </Stack>
            </Stack>
            <Button
                variant="contained"
                color="buttonPrimary"
                fullWidth
                onClick={onClick}
                sx={{
                    gap: '4px',
                    wordBreak: 'break-word',
                }}
                data-testid={selectors.newsCardDetailsButton}>
                <Box
                    sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}>
                    {buttonText}
                </Box>
                <ArrowOutwardIcon fontSize="small" />
            </Button>
        </Stack>
    );
};
