import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    Box,
    Button,
    Drawer,
    IconButton,
    Stack,
    Typography,
    styled,
} from '@mui/material';
import { useState } from 'react';

import { CartAccordion } from '@entities/cart/ui/cart-accordion';
import { TCartAccordionData } from '@entities/cart/ui/cart-accordion/cart-accordion';
import { FormCounterProvider } from '@features/form-counter';
import { formatCurrency, formatSpaces } from '@shared/lib/utils/number';
import { selectors } from '@shared/test/selectors';
import { Conditional } from '@shared/ui/atoms/conditional';

type TProps = {
    items: TCartAccordionData[];
    cartAmount: number;
    totalPrice: number;
    isOpen: boolean;
    onClose: () => void;
    onRemoveCart: () => void;
    onCartInfoClick: () => void;
    onFinance: () => void;
};

const Wrapper = styled(Stack)`
    padding: 20px 40px;
    gap: 10px;
    height: 100dvh;
    justify-content: space-between;
    background: ${({ theme }) => theme.palette.bg.primaryInvert};
`;

export const CartWidget = ({
    items,
    cartAmount,
    totalPrice,
    isOpen,
    onClose,
    onRemoveCart,
    onCartInfoClick,
    onFinance,
}: TProps) => {
    const [openAccordions, setOpenAccordions] = useState<number[]>([]);
    const isCartEmpty = items.length === 0;
    return (
        <Drawer anchor="right" open={isOpen} onClose={onClose}>
            <Wrapper
                direction="column"
                sx={{ width: { md: '650px' } }}
                data-testid={selectors.cartWidgetModal}>
                <Stack direction="column" gap="20px" height="90%">
                    <Stack direction="column" gap="8px" alignItems="start">
                        <Stack
                            direction="row"
                            width="100%"
                            justifyContent="space-between"
                            alignItems="center">
                            <Stack
                                direction="row"
                                alignItems="flex-start"
                                gap="5px">
                                <Typography
                                    variant="h2"
                                    color={theme =>
                                        theme.palette.typography.primaryInvert
                                    }>
                                    корзина
                                </Typography>
                                <Typography
                                    variant="h4"
                                    color={theme =>
                                        theme.palette.typography.secondaryInvert
                                    }>
                                    {!isCartEmpty && cartAmount > 99
                                        ? '99+'
                                        : formatSpaces(cartAmount)}
                                </Typography>
                            </Stack>
                            <Box>
                                <IconButton
                                    data-testid={
                                        selectors.cartWidgetCloseButton
                                    }
                                    onClick={onClose}
                                    color="buttonPrimaryInvert">
                                    <CloseIcon sx={{ fontSize: '48px' }} />
                                </IconButton>
                            </Box>
                        </Stack>
                        <Conditional condition={!isCartEmpty}>
                            <Button
                                data-testid={selectors.cartWidgetRemoveButton}
                                variant="text"
                                color="buttonInvertSecondaryText"
                                size="small"
                                onClick={onRemoveCart}
                                startIcon={
                                    <DeleteIcon
                                        sx={{
                                            color: 'inherit',
                                            fontSize: '16px',
                                        }}
                                    />
                                }>
                                очистить всю корзину
                            </Button>
                        </Conditional>
                    </Stack>
                    {isCartEmpty ? (
                        <Stack
                            direction="column"
                            flex={1}
                            justifyContent="center">
                            <Typography
                                variant="h2"
                                color={theme =>
                                    theme.palette.typography.secondaryInvert
                                }>
                                в корзине пока <br /> ничего нет...
                            </Typography>
                            <Typography
                                variant="body2"
                                color={theme =>
                                    theme.palette.typography.secondaryInvert
                                }>
                                воспользуйтесь поиском, если ищете что-то <br />
                                конкретное
                            </Typography>
                        </Stack>
                    ) : (
                        <Stack
                            direction="column"
                            overflow="auto"
                            justifyContent="space-between"
                            paddingRight="10px"
                            height="100%"
                            gap="8px">
                            <Stack direction="column">
                                {items?.map((item, index) => (
                                    <CartAccordion
                                        key={index}
                                        onInfoClick={onCartInfoClick}
                                        onFinance={onFinance}
                                        {...item}
                                        isOpen={openAccordions.includes(index)}
                                        onChangeAccordionState={() =>
                                            setOpenAccordions(prev => {
                                                if (prev.includes(index)) {
                                                    return prev.filter(
                                                        item => item !== index,
                                                    );
                                                }
                                                return [...prev, index];
                                            })
                                        }
                                        FormCounter={(
                                            initValue: number,
                                            maxValue: number,
                                            onChange: (value: number) => void,
                                        ) => (
                                            <FormCounterProvider
                                                initValue={initValue}
                                                onSubmit={data =>
                                                    onChange(data.counter)
                                                }
                                                maxValue={maxValue}
                                                minValue={0}
                                            />
                                        )}
                                    />
                                ))}
                            </Stack>
                            <Button
                                variant="contained"
                                color="buttonInvertSecondaryEnabled"
                                onClick={onFinance}>
                                Получить финансирование
                            </Button>
                        </Stack>
                    )}
                </Stack>
                <Conditional condition={!isCartEmpty}>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        flexWrap="wrap"
                        height="10%">
                        <Typography
                            variant="body2"
                            color={theme =>
                                theme.palette.typography.secondaryInvert
                            }>
                            Общая сумма заказов:
                        </Typography>
                        <Typography
                            variant="h4"
                            color={theme =>
                                theme.palette.typography.primaryInvert
                            }>
                            {formatCurrency(totalPrice)}
                        </Typography>
                    </Stack>
                </Conditional>
            </Wrapper>
        </Drawer>
    );
};
