import { selectors } from '@shared/test/selectors';

export const QuestionMarkIcon = () => {
    return (
        <svg
            data-testid={selectors.infoModalQuestionIcon}
            width="100%"
            height="100%"
            viewBox="0 0 273 462"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.2184 49.896C48.2217 17.9418 91.6206 -0.00606128 136.87 1.53553e-06H143.253C215.095 1.53553e-06 273 59.29 273 130.684C273.007 154.323 266.452 177.5 254.063 197.64C241.674 217.78 223.937 234.093 202.823 244.768C186.846 252.745 173.409 265.01 164.02 280.188C154.631 295.366 149.66 312.857 149.666 330.7V369.6H118.832V330.669C118.832 282.621 145.997 238.7 189.009 217.232C205.008 209.119 218.444 196.736 227.827 181.459C237.21 166.182 242.174 148.608 242.166 130.684C242.166 75.922 197.705 30.8 143.253 30.8H136.87C99.7931 30.8004 64.2349 45.5134 38.0177 71.7024L21.8301 87.9032L0 66.0968L16.2184 49.896ZM149.666 462H118.832V431.2H149.666V462Z"
                fill="currentColor"
            />
        </svg>
    );
};
