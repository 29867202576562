import { useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { UnknownBusinessError, userApi } from '@shared/api';
import { V1UserHistoryResponse } from '@shared/api/axios-client';

import { userKeys } from '../query-keys';
import { setSearchHistory } from '../user-domain';

type TBusinessError = UnknownBusinessError;

type TUseGetSearchHistory = {
    onSuccess?: (data: AxiosResponse<V1UserHistoryResponse, any>) => void;
    onError?: (businessError: TBusinessError) => void;
};

export const useGetSearchHistory = (props?: TUseGetSearchHistory) => {
    return useQuery(
        userKeys.searchHistory.queryKey,
        () => {
            return userApi.getV1UserSearchHistory({
                headers: {
                    Authorization: 'Bearer',
                },
            });
        },
        {
            onSuccess: data => {
                setSearchHistory(data.data.articles);
                props?.onSuccess && props.onSuccess(data);
            },
            onError: ({ response }: AxiosError<TBusinessError>) => {
                if (!props?.onError) {
                    return;
                }
                if (response?.data.code && response.data.message) {
                    props.onError(response.data);
                } else {
                    props.onError({
                        code: 'unknown_code',
                        message: 'что-то пошло не так',
                        response,
                    });
                }
            },
        },
    );
};
