import { TProductTable } from '@entities/products/types';
import { makeField } from '@entities/products/utils/make-product-table-field';
import { ProductInfo } from '@shared/api/axios-client';
import { dateUtils, numberUtils } from '@shared/lib/utils';
import { formatDistance } from '@shared/lib/utils/date';

import { EmptyDate } from '../../ui/empty-date';
export const productMap = (
    products?: ProductInfo[] | null,
    isConstDate?: boolean,
): TProductTable[] => {
    if (!products) {
        return [];
    }
    const bestPrice = products.reduce((acc, product) => {
        if (!product.warehouse) {
            return acc;
        }
        return product.warehouse.price < acc ? product.warehouse?.price : acc;
    }, Infinity);
    return products.map(product => {
        const today = new Date();
        today.setHours(0, 0, 0);
        const isValidDate = Boolean(product.warehouse.shipmentDate);
        const stringDate = isConstDate
            ? '90 дней'
            : formatDistance(
                  today,
                  dateUtils.validateDate(product.warehouse?.shipmentDate || ''),
              );
        return {
            id: product.product?.id || '',
            providerId: product.providers?.id || '',
            warehouseId: product.warehouse?.id || '',
            brand: product.product.brand,
            fields: [
                makeField(product.providers?.name),
                makeField(product.product?.brand),
                makeField(product.product.article),
                makeField(product.product?.name, 'left', 4),
                makeField(product.warehouse?.used ? 'б/у' : 'новое'),
                isValidDate
                    ? makeField(stringDate, 'right')
                    : {
                          Component: <EmptyDate />,
                      },
                makeField(
                    product.warehouse?.stock
                        ? String(
                              product.warehouse.stock < 100
                                  ? product.warehouse.stock
                                  : '> 100',
                          )
                        : '0',
                    'right',
                ),
                makeField(
                    numberUtils.formatCurrency(product.warehouse?.price || 0),
                    'right',
                    1,
                    true,
                    bestPrice === product.warehouse?.price
                        ? 'Лучшая цена'
                        : undefined,
                    '#FCFF51',
                ),
            ],
        };
    });
};
