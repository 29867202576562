import { Components, Theme } from '@mui/material';

import { LinkBehavior } from './link-behavior';

export const MuiLink: Components<Omit<Theme, 'components'>>['MuiLink'] = {
    defaultProps: {
        component: LinkBehavior,
    },
    styleOverrides: {
        root: {
            textDecoration: 'none',
        },
    },
};
