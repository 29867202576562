import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import EditIcon from '@mui/icons-material/Edit';
import { TabContext, TabPanel } from '@mui/lab';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Grid,
    Stack,
    Tab,
    Tabs,
    Typography,
} from '@mui/material';
import { useState } from 'react';

import { FeatureToggle } from '@features/feature-toggle';
import { selectors } from '@shared/test/selectors';
import { Conditional } from '@shared/ui/atoms/conditional';

import { helpData } from './data';

type TProps = {
    onSupportClick: () => void;
};

const IS_PROVIDERS_ENABLED = FeatureToggle.hasFeatureFlag('providers');

type TMenuNames = 'vitrina' | 'providers' | 'search' | 'other';

const tabValues = {
    vitrina: 'vitrina',
    providers: 'providers',
    search: 'search',
    other: 'other',
};
export const Help = ({ onSupportClick }: TProps) => {
    const [activeTab, setActiveTabs] = useState<TMenuNames>('vitrina');
    return (
        <Grid container direction="row">
            <Grid item xs={0} md={2} />
            <Grid item xs={12} md={8} direction="column">
                <TabContext value={activeTab}>
                    <Stack direction="column" gap="60px">
                        <Stack direction="row">
                            <Tabs
                                value={activeTab}
                                variant="scrollable"
                                onChange={(_, value) => setActiveTabs(value)}>
                                <Tab
                                    data-testid={selectors.accountHelpTab(
                                        tabValues.vitrina,
                                    )}
                                    label="О сервисе"
                                    value={tabValues.vitrina}
                                />
                                <Conditional condition={IS_PROVIDERS_ENABLED}>
                                    <Tab
                                        data-testid={selectors.accountHelpTab(
                                            tabValues.providers,
                                        )}
                                        label="О поставщиках"
                                        value={tabValues.providers}
                                    />
                                </Conditional>
                                <Tab
                                    data-testid={selectors.accountHelpTab(
                                        tabValues.search,
                                    )}
                                    label="О поиске товаров"
                                    value={tabValues.search}
                                />
                                <Tab
                                    data-testid={selectors.accountHelpTab(
                                        tabValues.other,
                                    )}
                                    label="Другое"
                                    value={tabValues.other}
                                />
                            </Tabs>
                        </Stack>
                        <Stack>
                            {Object.keys(helpData).map(item => (
                                <TabPanel value={item}>
                                    {helpData[item as TMenuNames].map(
                                        (data, index) => (
                                            <Conditional
                                                condition={data.isVisible}
                                                key={index}>
                                                <Accordion
                                                    data-testid={selectors.accountHelpAccordion(
                                                        String(index),
                                                    )}
                                                    sx={theme => ({
                                                        borderRadius:
                                                            '0 !important',
                                                        borderBottom: `1px solid ${theme.palette.border.border3}`,
                                                        '&::before': {
                                                            opacity: 0,
                                                        },
                                                        '&:hover > div > div > svg':
                                                            {
                                                                color: theme
                                                                    .palette
                                                                    .icon
                                                                    .primary,
                                                            },
                                                        '&:hover': {
                                                            borderColor:
                                                                theme.palette
                                                                    .icon
                                                                    .primary,
                                                        },
                                                    })}>
                                                    <AccordionSummary
                                                        expandIcon={
                                                            <ArrowDownwardIcon fontSize="large" />
                                                        }>
                                                        <Typography
                                                            variant="h4"
                                                            dangerouslySetInnerHTML={{
                                                                __html: data.question,
                                                            }}
                                                        />
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Typography
                                                            variant="body2"
                                                            dangerouslySetInnerHTML={{
                                                                __html: data.answer,
                                                            }}
                                                        />
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Conditional>
                                        ),
                                    )}
                                </TabPanel>
                            ))}
                        </Stack>
                    </Stack>
                </TabContext>
            </Grid>
            <Grid item xs={12} md={2}>
                <Stack direction="row" justifyContent="end">
                    <Button
                        data-testid={selectors.accountHelpSupportButton}
                        variant="contained"
                        sx={{ width: { xs: '100%', md: 'auto' } }}
                        startIcon={<EditIcon />}
                        color="buttonPrimary"
                        onClick={onSupportClick}>
                        написать в поддержку
                    </Button>
                </Stack>
            </Grid>
        </Grid>
    );
};
