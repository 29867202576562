import { Components, Theme } from '@mui/material';

type TContainedButtonsColors =
    | 'buttonPrimary'
    | 'buttonSecondaryEnabled'
    | 'buttonPrimaryInvert'
    | 'buttonInvertSecondaryEnabled';

type TTextButtonsColors =
    | 'buttonPrimary'
    | 'buttonSecondaryText'
    | 'buttonPrimaryInvert'
    | 'buttonInvertSecondaryText';

const getContainedButtonStyle = (
    theme: Theme,
    color: TContainedButtonsColors,
) => ({
    color: theme.palette[color].contrastText,
    border: '1px solid transparent',
    '&:focus': {
        borderColor: theme.palette.button[`${color}State`].border,
    },
    '&:disabled': {
        background: theme.palette.button[`${color}State`].disabledBackground,
        color: theme.palette.button[`${color}State`].disabled,
    },
});

const getTextButtonStyle = (theme: Theme, color: TTextButtonsColors) => ({
    color: theme.palette[color].main,
    '&:hover': {
        color: theme.palette[color].dark,
    },
    '&:focus': {
        color: theme.palette[color].dark,
        borderColor: theme.palette.button[`${color}State`].border,
    },
    '&:disabled': {
        color: theme.palette.button[`${color}State`].disabled,
    },
});

export const MuiButton: Components<Omit<Theme, 'components'>>['MuiButton'] = {
    variants: [
        {
            props: {
                color: 'buttonPrimary',
                variant: 'contained',
            },
            style: ({ theme }) =>
                getContainedButtonStyle(theme, 'buttonPrimary'),
        },
        {
            props: {
                color: 'buttonSecondaryEnabled',
                variant: 'contained',
            },
            style: ({ theme }) =>
                getContainedButtonStyle(theme, 'buttonSecondaryEnabled'),
        },
        {
            props: {
                color: 'buttonPrimaryInvert',
                variant: 'contained',
            },
            style: ({ theme }) =>
                getContainedButtonStyle(theme, 'buttonPrimaryInvert'),
        },
        {
            props: {
                color: 'buttonInvertSecondaryEnabled',
                variant: 'contained',
            },
            style: ({ theme }) =>
                getContainedButtonStyle(theme, 'buttonInvertSecondaryEnabled'),
        },

        {
            props: {
                color: 'buttonPrimary',
                variant: 'text',
            },
            style: ({ theme }) => getTextButtonStyle(theme, 'buttonPrimary'),
        },
        {
            props: {
                color: 'buttonSecondaryText',
                variant: 'text',
            },
            style: ({ theme }) =>
                getTextButtonStyle(theme, 'buttonSecondaryText'),
        },
        {
            props: {
                color: 'buttonPrimaryInvert',
                variant: 'text',
            },
            style: ({ theme }) =>
                getTextButtonStyle(theme, 'buttonPrimaryInvert'),
        },
        {
            props: {
                color: 'buttonInvertSecondaryText',
                variant: 'text',
            },
            style: ({ theme }) =>
                getTextButtonStyle(theme, 'buttonInvertSecondaryText'),
        },
    ],
    defaultProps: {
        disableElevation: true,
    },
    styleOverrides: {
        sizeMedium: {
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '24px',
        },
        contained: {
            borderRadius: '30px',
            padding: '8px 15px 10px 15px',
            textTransform: 'none',
        },
        outlined: ({ theme }) => ({
            borderRadius: '30px',
            textTransform: 'none',
            border: `1px solid ${theme.palette.icon.secondary}`,
        }),
        text: {
            background: 'none',
            textTransform: 'none',
            '&:hover': {
                background: 'none',
            },
            '&:focus': {
                background: 'none',
            },
            '&:disabled': {
                background: 'none',
            },
            '&.MuiLoadingButton-loading': {
                '>.MuiLoadingButton-loadingIndicator': {
                    color: '#687AFF',
                },
            },
            '>.MuiButton-startIcon': {
                marginRight: '4px',
                marginLeft: 0,
            },
            '&.MuiButton-sizeMedium': {
                padding: '8px 10px 10px 10px',
                borderRadius: '30px',
                border: '1px solid transparent',
            },
            '&.MuiButton-sizeSmall': {
                padding: '4px 0 4px 0',
                borderRadius: '0',
                borderBottom: '1px solid transparent',
            },
        },
    },
};
