export const selectors = {
    //entities/add
    addCard: (id: string) => `add-card-${id}`,
    //entities/user
    accountAvatar: 'account-avatar',
    accountCard: 'account-card',
    userClientIdCard: 'user-client-id-card',
    userClientIdCardChangeButton: 'user-client-id-card-change-button',
    //entities/cart
    cartAccordion: (id: string) => `cart-accordion-${id}`,
    cartAccordionRemoveButton: 'cart-accordion-remove-button',
    cartDialogPrimaryButton: 'cart-dialog-primary-button',
    cartDialogSecondaryButton: 'cart-dialog-secondary-button',
    cartItemCardTooltip: 'cart-item-card-tooltip',
    cartItemCardRemoveButton: 'cart-item-card-remove-button',
    cartTotalInfoButton: 'cart-total-info-button',
    cartTotalSubmitButton: 'cart-total-submit-button',
    cartTotalFinanceButton: 'cart-total-finance-button',
    //entities/favorite
    favoriteCardItem: 'favorite-card-item',
    favoriteCardItemRemoveButton: 'favorite-card-item-remove-button',
    favoriteCardItemActionButton: 'favorite-card-item-action-button',
    //entities/news
    newsCardButton: 'news-card-button',
    newsCardDetailsCloseButton: 'news-card-details-close-button',
    newsCardDetailsButton: 'news-card-details-button',
    newsCard: 'news-card',
    newsSection: 'news-section',
    //entities/products
    productsProductDeliveryFilterAutocomplete:
        'products-product-delivery-filter-autocomplete',
    productsProductDeliveryFilterOption: (id: string) =>
        `products-product-delivery-filter-option-${id}`,
    productsProductDeliveryFilterOptionRadio: (id: string) =>
        `products-product-delivery-filter-option-radio-${id}`,
    productsProductDeliveryFilterTextField:
        'products-product-delivery-filter-text-field',
    productsProductItem: 'products-product-item',
    productsProductItemFieldTooltip: 'products-product-item-field-tooltip',
    productsProductPriceFilter: 'products-product-price-filter',
    productsProductPriceFilterFromInput:
        'products-product-price-filter-from-input',
    productsProductPriceFilterToInput: 'products-product-price-filter-to-input',
    productsProductProviderFilterAutocomplete:
        'products-product-provider-filter-autocomplete',
    productsProductProviderFilterCheckbox: (id: number) =>
        `products-product-provider-filter-checkbox-${id}`,
    productsProductProviderFilterInput:
        'products-product-provider-filter-input',
    productsProductTableHeaderField: (id: string) =>
        `products-product-table-header-field-${id}`,
    productsProductsEmptyHelpButton: `products-products-empty-help-button`,
    productsProductsEmptyAddProviderButton: `products-products-empty-add-provider-button`,
    productsProductsEmptyPropositionProviderButton: `products-products-empty-proposition-provider-button`,
    productsProviderErrorAlertChangeButton: `products-provider-error-alert-change-button`,
    productsProviderErrorAlertCloseButton: `products-provider-error-alert-close-button`,
    //entities/providers
    providersProviderCredentialModal: 'providers-provider-credential-modal',
    providersProviderCredentialModalCloseButton:
        'providers-provider-credential-modal-close-button',
    providersProviderInfoCardChangeButton:
        'providers-provider-info-card-change-button',
    providersProviderInfoCardAddButton:
        'providers-provider-info-card-add-button',
    providerInfoCard: (id: string) => `provider-info-card-${id}`,
    //features
    formClientIdInput: 'form-client-id-input',
    formClientIdClearButton: 'form-client-id-clear-button',
    formClientIdSubmitButton: 'form-client-id-submit-button',
    formProviderChangeInput: 'form-provider-change-input',
    formProviderChangeClearButton: 'form-provider-change-clear-button',
    formProviderChangeSubmitButton: 'form-provider-change-submit-button',
    formProviderChangeCancelButton: 'form-provider-change-cancel-button',
    formProviderCredentialInput: 'form-provider-credential-input',
    formProviderCredentialClearButton: 'form-provider-credential-clear-button',
    formProviderCredentialSubmitButton:
        'form-provider-credential-submit-button',
    formProviderCredentialRemoveButton:
        'form-provider-credential-remove-button',
    formSearchHeaderAutocomplete: 'form-search-header-autocomplete',
    formSearchHeaderAutocompleteOption: (id: string) =>
        `form-search-header-autocomplete-option-${id}`,
    formSearchHeaderAutocompleteInput: 'form-search-header-autocompleteInput',
    formSearchSuggestAutocomplete: 'form-search-suggest-autocomplete',
    formSearchSuggestAutocompleteOption: (id: string) =>
        `form-search-suggest-autocomplete-option-${id}`,
    formSearchSuggestAutocompleteInput:
        'form-search-suggest-autocomplete-input',

    formSearchSuggestAutocompleteSubmitButton:
        'form-search-suggest-autocomplete-submit-button',
    formSearchSuggestAutocompleteClearButton:
        'form-search-suggest-autocomplete-clear-button',
    formFiltersClearButton: 'form-filters-clear-button',
    //pages/account
    accountChangeClientIdHeaderTooltip:
        'account-change-client-id-header-tooltip',
    accountChangeProvidersCounter: 'account-change-providers-counter',
    accountChangeProvidersEmptyPrimaryButton:
        'account-change-providers-empty-primary-button',
    accountChangeProvidersEmptySecondaryButton:
        'account-change-providers-empty-secondary-button',
    accountChangeProvidersActionButton:
        'account-change-providers-action-button',
    accountHelpTab: (id: string) => `account-help-tab-${id}`,
    accountHelpAccordion: (id: string) => `account-help-accordion-${id}`,
    accountHelpSupportButton: 'account-help-support-button',
    accountHomeChangeButton: 'account-home-change-button',
    //pages/auth
    authCompanyAuthModal: 'auth-company-auth-modal',
    authDriverModal: 'auth-driver-modal',
    authDriverBackButton: 'auth-driver-back-button',
    authPrimaryButton: 'auth-primary-button',
    authSecondaryButton: 'auth-secondary-button',
    //pages/catalog
    catalogBrandHelpButton: 'catalog-brand-help-button',
    catalogBrandSelectBrandButton: (id: string) =>
        `catalog-brand-select-brand-button-${id}`,
    catalogAddToCartButton: 'catalog-add-to-cart-button',
    catalogHelpButton: 'catalog-help-button',
    //pages/error
    errorNotFoundHomeButton: 'error-not-found-home-button',
    //pages/provider
    providerSaveButton: 'provider-save-button',
    providerSkipButton: 'provider-skip-button',
    providerPropositionButton: 'provider-proposition-button',
    //shared/ui
    logo: 'logo',
    headerContainer: 'header-container',
    checkboxAutocomplete: 'checkbox-autocomplete',
    checkboxAutocompleteInput: 'checkbox-autocomplete-input',
    checkboxAutocompleteOption: (id: string) =>
        `checkbox-autocomplete-option-${id}`,
    checkboxAutocompleteCheckbox: (id: string) =>
        `checkbox-autocomplete-checkbox-${id}`,
    checkboxAutocompleteChip: (id: string) =>
        `checkbox-autocomplete-chip-${id}`,
    counterRemoveButton: 'counter-remove-button',
    counterInput: 'counter-input',
    counterAddButton: 'counter-add-button',
    errorSnackbarContainer: 'error-snackbar-container',
    infoSnackbarActionButton: 'info-snackbar-action-button',
    infoSnackbarContainer: 'info-snackbar-container',
    infoSnackbarCloseButton: 'info-snackbar-close-button',
    radioButtonGroupButton: (id: string) => `radio-button-group-button-${id}`,
    dialogPrimaryButton: 'dialog-primary-button',
    dialogSecondaryButton: 'dialog-secondary-button',
    infoModalContainer: 'info-modal-container',
    infoModalErrorIcon: 'info-modal-error-icon',
    infoModalCloseButton: 'info-modal-close-button',
    infoModalQuestionIcon: 'info-modal-question-icon',
    infoModalPrimaryButton: 'info-modal-primary-button',
    infoModalSecondaryButton: 'info-modal-secondary-button',
    //widgets
    cartWidgetCloseButton: 'cart-widget-close-button',
    cartWidgetRemoveButton: 'cart-widget-remove-button',
    cartWidgetModal: 'cart-widget-modal',
    headerWidgetLogoutButton: 'header-widget-logout-button',
    headerWidgetLoginButton: 'header-widget-login-button',
    oauthYandexWidgetPrimaryButton: 'oauth-yandex-widget-primary-button',
    oauthYandexWidgetButtonContainer: 'oauth-yandex-widget-button-container',
    topbarMenuWidgetDrawer: 'topbar-menu-widget-drawer',
    topbarMenuWidgetCloseButton: 'topbar-menu-widget-close-button',
    topbarMenuWidgetLogoutButton: 'topbar-menu-widget-logout-button',
    topbarMenuWidgetMenuItemLink: 'topbar-menu-widget-menu-item-link',
    topbarMenuWidgetMenuItemFeatureLink: (id: string) =>
        `topbar-menu-widget-menu-item-feature-link-${id}`,
    topbarMenuWidgetMenuItemButton: 'topbar-menu-widget-menu-item-button',
    topbarMenuWidgetOpenDrawerButton: 'topbar-menu-widget-open-drawer-button',
};
