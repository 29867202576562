import { Box, Modal, ModalProps } from '@mui/material';

type TProps = {
    dataTestId: string;
} & ModalProps;

export const CenteredModal = ({ children, dataTestId, ...props }: TProps) => {
    return (
        <Modal {...props}>
            <Box
                data-testid={dataTestId}
                position="absolute"
                top="50%"
                left="50%"
                sx={{ transform: 'translate(-50%, -50%)' }}
                display="flex"
                justifyContent="center"
                width={{ xs: '100%', md: 'auto' }}>
                {children}
            </Box>
        </Modal>
    );
};
