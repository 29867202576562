import { Box } from '@mui/material';
import { ReactNode } from 'react';

type TProps = {
    condition?: boolean;
    width?: string;
    children: ReactNode;
};

export const Conditional = ({ condition, width, children }: TProps) => {
    const EmptyComponent = width ? <Box width={width} /> : null;
    return condition ? <>{children}</> : EmptyComponent;
};
