/**
 * Плюрализация чисел на русском языке.
 * @param {number} number - Число, которое нужно плюрализовать.
 * @param {[string, string, string]} forms - Массив из трех форм слова для `one`, `many`, `several`.
 * @returns {string} Плюрализованная строка.
 */

export const pluralize = (
    number: number,
    forms: [string, string, string],
    zeroValiant?: string,
): string => {
    const cases = [2, 0, 1, 1, 1, 2];
    if (zeroValiant && number === 0) {
        return 'сегодня';
    }
    const pluralizedNumber = `${number} ${
        forms[
            number % 100 > 4 && number % 100 < 20
                ? 2
                : cases[number % 10 < 5 ? number % 10 : 5]
        ]
    }`;

    return pluralizedNumber;
};
