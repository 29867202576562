import { Components, Theme } from '@mui/material';

export const MuiAutocomplete: Components<
    Omit<Theme, 'components'>
>['MuiAutocomplete'] = {
    defaultProps: {
        componentsProps: {
            paper: {
                sx: {
                    boxShadow: 'none',
                    '.MuiAutocomplete-option': {
                        padding: '0 !important',
                    },
                },
            },
        },
        ListboxProps: {
            sx: theme => ({
                '.MuiAutocomplete-option': {
                    padding: 0,
                },
                '.MuiAutocomplete-option[aria-selected="true"]': {
                    backgroundColor: 'transparent !important',
                },
                '.MuiAutocomplete-option:hover > div > h6': {
                    borderBottom: `1px solid ${theme.palette.icon.primary}`,
                },
            }),
        },
    },
    styleOverrides: {
        noOptions: ({ theme }) => ({
            ...theme.typography.body2,
            textAlign: 'center',
        }),
        root: ({ theme }) => ({
            '.MuiAutocomplete-inputRoot': {
                gap: '5px',
            },
            '.MuiAutocomplete-endAdornment': {
                display: 'flex',
            },
            '.MuiAutocomplete-popupIndicator': {
                color: theme.palette.icon.primary,
            },
        }),
    },
};
