import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { Button, Stack, Typography } from '@mui/material';
import { Controller, useController, useFormContext } from 'react-hook-form';

import {
    TFormDelivery,
    TFormDeliveryType,
    TFormState,
    TPriceLimit,
} from '@entities/products/types';
import { ProductDeliveryFilter } from '@entities/products/ui/product-delivery-filter';
import { ProductPriceFilter } from '@entities/products/ui/product-price-filter';
import { ProductProviderFilter } from '@entities/products/ui/product-provider-filter';
import { TFormProvider } from '@entities/providers/types';
import { selectors } from '@shared/test/selectors';
import { Conditional } from '@shared/ui/atoms/conditional';
import { RadioButtonGroup } from '@shared/ui/molecules/radio-button-group/radio-button-group';

type TProps = {
    providers: TFormProvider[];
    deliveryDateOptions: TFormDelivery[];
    deliveryTypeOptions: TFormDeliveryType[];
    stateOptions: TFormState[];
    priceLimit: TPriceLimit;
};

export type TFormData = {
    provider?: TFormProvider[];
    deliveryDate?: TFormDelivery;
    minPrice?: number;
    maxPrice?: number;
    deliveryType?: TFormDeliveryType;
    state?: TFormState;
};

export const FormFilters = ({
    providers,
    deliveryDateOptions,
    deliveryTypeOptions,
    stateOptions,
    priceLimit,
}: TProps) => {
    const { control, formState, reset } = useFormContext<TFormData>();
    const { field: minPriceField } = useController({
        name: 'minPrice',
        control,
    });
    const { field: maxPriceField } = useController({
        name: 'maxPrice',
        control,
    });
    return (
        <Stack direction="column" width="100%" gap="20px">
            <Stack alignItems="flex-end" paddingRight="40px" height="24px">
                <Conditional condition={formState.isDirty}>
                    <Button
                        data-testid={selectors.formFiltersClearButton}
                        variant="text"
                        startIcon={<FilterAltOffIcon />}
                        color="buttonSecondaryText"
                        onClick={() => {
                            reset();
                        }}>
                        очистить все фильтры
                    </Button>
                </Conditional>
            </Stack>
            <Stack
                direction="row"
                width="100%"
                flexWrap="wrap"
                justifyContent={{ xs: 'center', md: 'start' }}>
                <Controller
                    name="provider"
                    control={control}
                    render={({ field }) => (
                        <ProductProviderFilter
                            fields={field}
                            options={providers}
                        />
                    )}
                />
                <Controller
                    name="deliveryDate"
                    control={control}
                    render={({ field }) => (
                        <ProductDeliveryFilter
                            defaultValue={formState.defaultValues?.deliveryDate}
                            fields={field}
                            options={deliveryDateOptions}
                        />
                    )}
                />
                <ProductPriceFilter
                    control={control}
                    priceLimit={priceLimit}
                    formFields={{
                        minPrice: minPriceField.value,
                        maxPrice: maxPriceField.value,
                    }}
                    fieldPaths={{
                        minPrice: 'minPrice',
                        maxPrice: 'maxPrice',
                    }}
                />
                {/* (@OKA) temp remove  YAV-769*/}
                {/* <Stack
                    direction="column"
                    justifyContent="center"
                    padding="20px 20px">
                    <Typography variant="caption1">Тип получения</Typography>
                    <Controller
                        name="deliveryType"
                        control={control}
                        render={({ field }) => (
                            <RadioButtonGroup
                                onChange={field.onChange}
                                items={deliveryTypeOptions}
                                selectedItem={
                                    field.value || {
                                        value: 'any',
                                        label: 'Любой тип',
                                    }
                                }
                            />
                        )}
                    />
                </Stack> */}
                <Stack
                    direction="column"
                    justifyContent="center"
                    padding="20px 20px">
                    <Typography variant="caption1">Состояние</Typography>
                    <Controller
                        name="state"
                        control={control}
                        render={({ field }) => (
                            <RadioButtonGroup
                                onChange={field.onChange}
                                items={stateOptions}
                                selectedItem={
                                    field.value || {
                                        value: 'any',
                                        label: 'Любое',
                                    }
                                }
                            />
                        )}
                    />
                </Stack>
            </Stack>
        </Stack>
    );
};
