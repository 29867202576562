import DeleteIcon from '@mui/icons-material/Delete';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Divider,
    IconButton,
    Stack,
    Typography,
} from '@mui/material';
import { Fragment, ReactNode } from 'react';

import { TCart, TCartChangeItem, TCartRemoveItem } from '@entities/cart/types';
import { formatCurrency, formatSpaces } from '@shared/lib/utils/number';
import { selectors } from '@shared/test/selectors';
import { Conditional } from '@shared/ui/atoms/conditional';

import { CartItemCard } from '../cart-item-card';
import { CartTotal } from '../cart-total';

export type TCartAccordionData = {
    cart: TCart;
    title: string;
    isError: boolean;
    withProviderName: boolean;
    onChangeCart: (cartItem: TCartChangeItem) => void;
    onRemove: (cartItem: TCartRemoveItem) => void;
    onRemoveGroup: () => void;
    onExportGroup?: () => void;
};

type TProps = {
    isOpen: boolean;
    onInfoClick: () => void;
    onChangeAccordionState: () => void;
    onFinance: () => void;
    FormCounter: (
        initValue: number,
        maxValue: number,
        onChange: (value: number) => void,
    ) => ReactNode;
} & TCartAccordionData;

export const CartAccordion = ({
    cart,
    title,
    isError,
    isOpen,
    withProviderName,
    FormCounter,
    onRemove,
    onInfoClick,
    onChangeCart,
    onRemoveGroup,
    onExportGroup,
    onChangeAccordionState,
    onFinance,
}: TProps) => {
    return (
        <Accordion
            data-testid={selectors.cartAccordion(title)}
            expanded={isOpen}
            onChange={onChangeAccordionState}
            square
            sx={theme => ({
                borderBottom: isOpen
                    ? 'none'
                    : `1px solid ${theme.palette.icon.tertiaryInvert}`,
            })}>
            <AccordionSummary
                sx={{ flexDirection: 'row-reverse', gap: '15px' }}
                expandIcon={
                    <KeyboardArrowDownIcon
                        sx={theme => ({
                            color: theme.palette.typography.primaryInvert,
                        })}
                        fontSize="medium"
                    />
                }>
                <Stack
                    minHeight="40px"
                    alignItems="center"
                    direction="row"
                    justifyContent="space-between"
                    width="100%"
                    flexWrap="wrap">
                    <Stack
                        direction="row"
                        gap="4px"
                        overflow="hidden"
                        maxWidth={{ xs: '150px', md: '500px' }}>
                        <Typography
                            variant="h5"
                            color={theme =>
                                theme.palette.typography.primaryInvert
                            }
                            textOverflow="ellipsis"
                            overflow="hidden">
                            {title}
                        </Typography>
                        <Typography
                            variant="caption2"
                            color={theme =>
                                theme.palette.typography.secondaryInvert
                            }>
                            {cart.totalAmount < 100
                                ? formatSpaces(cart.totalAmount)
                                : '99+'}
                        </Typography>
                    </Stack>
                    <Stack
                        direction="row"
                        gap="12px"
                        alignItems="center"
                        sx={{ wordBreak: 'break-word' }}
                        maxWidth="200px">
                        <Conditional
                            condition={isError && !isOpen}
                            width="24px">
                            <ErrorOutlineIcon
                                sx={theme => ({
                                    fontSize: '13px',
                                    color: theme.palette.icon.error,
                                })}
                            />
                        </Conditional>
                        {isOpen ? (
                            <IconButton
                                onClick={e => {
                                    e.stopPropagation();
                                    onRemoveGroup();
                                }}
                                color="buttonInvertSecondaryText"
                                data-testid={
                                    selectors.cartAccordionRemoveButton
                                }>
                                <DeleteIcon fontSize="medium" />
                            </IconButton>
                        ) : (
                            <Typography
                                variant="h5"
                                color={theme =>
                                    theme.palette.typography.primaryInvert
                                }>
                                {formatCurrency(cart.totalPrice)}
                            </Typography>
                        )}
                    </Stack>
                </Stack>
            </AccordionSummary>
            <AccordionDetails>
                <Stack
                    direction="column"
                    border={theme =>
                        `1px solid ${theme.palette.icon.tertiaryInvert}`
                    }
                    borderRadius="5px">
                    {cart.items.map(item => (
                        <Fragment key={item.id + item.warehouseId}>
                            <CartItemCard
                                {...item}
                                withProviderName={withProviderName}
                                onRemove={() =>
                                    onRemove({
                                        productId: item.id,
                                        providerId: item.providerId,
                                        warehouseId: item.warehouseId,
                                    })
                                }
                                FormComponent={FormCounter(
                                    item.amount,
                                    item.stock,
                                    value =>
                                        onChangeCart({
                                            productId: item.id,
                                            providerId: item.providerId,
                                            warehouseId: item.warehouseId,
                                            quantity: value,
                                        }),
                                )}
                            />
                            <Divider
                                orientation="horizontal"
                                variant="fullWidth"
                                sx={theme => ({
                                    background:
                                        theme.palette.icon.tertiaryInvert,
                                })}
                            />
                        </Fragment>
                    ))}
                    <CartTotal
                        totalPrice={cart.totalPrice}
                        minOrderPrice={cart.minOrderPrice}
                        isInfo={cart.instantOrderAvailable}
                        isError={isError}
                        onSubmit={onExportGroup}
                        onInfoClick={onInfoClick}
                        onFinance={onFinance}
                    />
                </Stack>
            </AccordionDetails>
        </Accordion>
    );
};
