export const RadioOnIcon = () => {
    return (
        <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7 0C3.1339 0 0 3.1339 0 7C0 10.8661 3.1339 14 7 14C10.8661 14 14 10.8661 14 7C14 3.1339 10.8661 0 7 0ZM7 4.2C6.25739 4.2 5.5452 4.495 5.0201 5.0201C4.495 5.5452 4.2 6.25739 4.2 7C4.2 7.74261 4.495 8.4548 5.0201 8.9799C5.5452 9.505 6.25739 9.8 7 9.8C7.74261 9.8 8.4548 9.505 8.9799 8.9799C9.505 8.4548 9.8 7.74261 9.8 7C9.8 6.25739 9.505 5.5452 8.9799 5.0201C8.4548 4.495 7.74261 4.2 7 4.2Z"
                fill="currentColor"
            />
        </svg>
    );
};
