import { Components, Theme } from '@mui/material';

import { DeleteIcon } from '../../icons/delete-icon';

export const MuiChip: Components<Omit<Theme, 'components'>>['MuiChip'] = {
    defaultProps: {
        deleteIcon: DeleteIcon({
            onMouseDown: event => event.stopPropagation(),
        }),
    },
    styleOverrides: {
        root: ({ theme }) => ({
            padding: '10px',
            border: 'none',
            '.MuiChip-label': {
                fontFamily: theme.typography.h4.fontFamily,
                fontSize: theme.typography.h4.fontSize,
                fontStyle: theme.typography.h4.fontStyle,
                fontWeight: theme.typography.h4.fontWeight,
                lineHeight: theme.typography.h4.lineHeight,
                letterSpacing: theme.typography.h4.letterSpacing,
                paddingLeft: 0,
            },
            '.MuiChip-deleteIcon': {
                color: theme.palette.icon.onlyDark,
                marginRight: 0,
            },
        }),
    },
};
