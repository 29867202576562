import { TCart, TCartChangeItem, TCartRemoveItem } from '@entities/cart/types';
import { TCartAccordionData } from '@entities/cart/ui/cart-accordion/cart-accordion';
import { V1CartResponse } from '@shared/api/axios-client';
import { dateUtils } from '@shared/lib/utils';
import { EDateFormat } from '@shared/lib/utils/date';

export const mapCart = (
    onRemoveGroup: (providerId: string) => void,
    onChangeCart: (cartItem: TCartChangeItem) => void,
    onRemove: (cartItem: TCartRemoveItem) => void,
    onExportGroup: (cart: TCart) => void,
    cartProducts?: V1CartResponse,
): TCartAccordionData[] => {
    if (!cartProducts) {
        return [];
    }

    return cartProducts.cart.map(cart => {
        const cartItem: TCart = {
            id: `${cartProducts.id}${cart.providerId}`,
            totalAmount: cart.totalQuantity,
            totalPrice: cart.totalPrice,
            instantOrderAvailable: cart.instantOrderAvailable,
            minOrderPrice: cart.minOrderPrice || 0,
            providerName: cart.providerName,
            providerId: cart.providerId,
            items: cart.products.map(product => {
                const date = product.warehouse.shipmentDate?.split(' ');
                const formattedDate = date ? date[0] + 'T' + date[1] : '';
                return {
                    id: product.product.id,
                    providerId: product.provider.id,
                    brand: product.product.brand,
                    providerName: product.warehouse.shortName, //Temp abcp solution
                    warehouseId: product.warehouse.id,
                    price: product.warehouse.price,
                    title: product.product.name,
                    article: product.product.article,
                    deliveryDate:
                        formattedDate &&
                        dateUtils.formatDate(
                            formattedDate,
                            EDateFormat.ddMMyyyy,
                        ),
                    amount: product.warehouse.quantity,
                    stock: product.warehouse.stock,
                };
            }),
        };
        return {
            cart: cartItem,
            title: cart.providerId === 'abcp' ? 'Проценка' : cart.providerName,
            isError: cart.totalPrice <= cartItem.minOrderPrice,
            withProviderName: cart.providerId === 'abcp',
            onRemoveGroup: () => onRemoveGroup(cart.providerId),
            onExportGroup:
                cart.providerId !== 'abcp'
                    ? () => onExportGroup(cartItem)
                    : undefined,
            onChangeCart,
            onRemove,
        };
    });
};
