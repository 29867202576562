import { Components, Theme } from '@mui/material';

import { CheckOffIcon, CheckerIcon } from '@shared/ui/icons';

export const MuiCheckbox: Components<Omit<Theme, 'components'>>['MuiCheckbox'] =
    {
        defaultProps: {
            checkedIcon: CheckerIcon({}),
            icon: CheckOffIcon({}),
        },
        styleOverrides: {
            root: ({ theme }) => ({
                minWidth: '42px',
                '&.Mui-checked': {
                    color: theme.palette.icon.primary,
                },
            }),
        },
    };
