import { defaultFlags } from './default-flags';

export const nameFlags: Record<keyof typeof defaultFlags, string> = {
    favorite: 'Избранное',
    newses: 'Новости',
    adds: 'Реклама',
    yandexProducts: 'Товары от яндекса',
    providers: 'Поставщики',
    priceList: 'Скачивание прайс листа',
};
