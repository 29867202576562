import { SvgIconProps } from '@mui/material';

export const SuccessOutlinedIcon = (props: SvgIconProps) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 106 106"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M53 3.84223e-05C59.9613 -0.00835571 66.8556 1.35873 73.287 4.02278C79.7183 6.68681 85.5599 10.5952 90.4762 15.5236C95.4045 20.44 99.313 26.2817 101.977 32.7131C104.641 39.1444 106.008 46.0387 106 52.9999C106.008 59.9612 104.641 66.8556 101.977 73.287C99.3132 79.7184 95.4047 85.56 90.4763 90.4763C85.56 95.4047 79.7184 99.3132 73.287 101.977C66.8556 104.641 59.9612 106.008 52.9999 106C46.0387 106.008 39.1444 104.641 32.7131 101.977C26.2817 99.313 20.44 95.4045 15.5236 90.4762C10.5952 85.5599 6.68681 79.7183 4.02278 73.287C1.35873 66.8556 -0.00835571 59.9613 3.84223e-05 53C-0.00822368 46.0387 1.35893 39.1444 4.02297 32.7131C6.68701 26.2817 10.5954 20.4401 15.5237 15.5237C20.4401 10.5954 26.2817 6.68701 32.7131 4.02297C39.1444 1.35893 46.0387 -0.00822368 53 3.84223e-05ZM70.4894 10.7766C64.9465 8.48059 59.0044 7.30261 53.0047 7.31038H52.9953C46.9957 7.30273 41.0536 8.48078 35.5107 10.7768C29.9677 13.0729 24.9331 16.4416 20.6963 20.6895L20.6895 20.6963C16.4416 24.9331 13.0729 29.9677 10.7768 35.5107C8.48078 41.0536 7.30273 46.9957 7.31038 52.9953V53.0047C7.30261 59.0044 8.48059 64.9465 10.7766 70.4894C13.0727 76.0323 16.4415 81.0669 20.6895 85.3037L20.6963 85.3104C24.9331 89.5584 29.9677 92.9271 35.5107 95.2232C41.0536 97.5192 46.9957 98.6973 52.9953 98.6896H53.0047C59.0044 98.6974 64.9465 97.5194 70.4894 95.2234C76.0323 92.9273 81.0669 89.5585 85.3037 85.3105L85.3105 85.3037C89.5585 81.0669 92.9273 76.0323 95.2234 70.4894C97.5194 64.9465 98.6974 59.0044 98.6896 53.0047V52.9953C98.6973 46.9957 97.5192 41.0536 95.2232 35.5107C92.9271 29.9677 89.5584 24.9331 85.3105 20.6963L85.3037 20.6895C81.0669 16.4415 76.0323 13.0727 70.4894 10.7766ZM76.6803 37.2995C78.0842 38.7501 78.0463 41.0641 76.5956 42.468L49.4012 68.7852C47.984 70.1566 45.7345 70.1566 44.3174 68.7852L30.7202 55.6266C29.2695 54.2227 29.2316 51.9087 30.6354 50.4581C32.0393 49.0074 34.3533 48.9695 35.8039 50.3733L46.8593 61.0721L71.5118 37.2147C72.9625 35.8109 75.2765 35.8488 76.6803 37.2995Z"
                fill="currentColor"
            />
        </svg>
    );
};
