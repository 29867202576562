import { Modal, ModalProps, Slide } from '@mui/material';

type TProps = {} & ModalProps;

export const SlideModal = ({ children, open, ...modalProps }: TProps) => {
    return (
        <Modal {...modalProps} open={open}>
            <Slide
                direction="down"
                in={open}
                mountOnEnter
                timeout={300}
                unmountOnExit>
                {/* https://github.com/mui/material-ui/issues/38141 */}
                <div>{children}</div>
            </Slide>
        </Modal>
    );
};
