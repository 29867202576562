import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import { CustomContentProps, SnackbarContent, useSnackbar } from 'notistack';
import { ReactElement, forwardRef } from 'react';

import { selectors } from '@shared/test/selectors';
import { Conditional } from '@shared/ui/atoms/conditional';

type TProps = CustomContentProps & {
    withIcon?: boolean;
    closable?: boolean;
    actionContent?: ReactElement;
    onAction?: () => void;
};

export const InfoSnackbar = forwardRef<HTMLDivElement, TProps>((props, ref) => {
    const {
        id,
        withIcon,
        message: title,
        closable,
        actionContent,
        onAction,
        anchorOrigin,
        autoHideDuration,
        hideIconVariant,
        iconVariant,
        persist,
        ...other
    } = props;
    const { closeSnackbar } = useSnackbar();
    return (
        <SnackbarContent ref={ref} role="alert" {...other}>
            <Stack
                direction="row"
                gap="8px"
                padding="12px 20px"
                borderRadius="12px"
                minWidth="400px"
                justifyContent="space-between"
                data-testid={selectors.infoSnackbarContainer}
                sx={theme => ({ background: theme.palette.icon.primary })}>
                <Stack direction="row" gap="10px">
                    <Conditional condition={withIcon}>
                        <ErrorOutlineIcon
                            sx={theme => ({
                                color: theme.palette.icon.onlyWhite,
                                fontSize: '28px',
                            })}
                        />
                    </Conditional>
                    <Typography
                        variant="subtitle1"
                        color={theme => theme.palette.typography.primaryInvert}>
                        {title}
                    </Typography>
                </Stack>
                <Stack direction="row">
                    <Conditional condition={Boolean(actionContent)}>
                        <Button
                            data-testid={selectors.infoSnackbarActionButton}
                            onClick={() => {
                                onAction?.();
                                closeSnackbar(id);
                            }}
                            size="small"
                            variant="text"
                            sx={theme => ({
                                color: theme.palette.error.contrastText,
                            })}>
                            {actionContent}
                        </Button>
                    </Conditional>
                    <Conditional condition={closable}>
                        <IconButton
                            data-testid={selectors.infoSnackbarCloseButton}
                            onClick={() => closeSnackbar(id)}>
                            <CloseIcon
                                sx={theme => ({
                                    color: theme.palette.icon.onlyWhite,
                                })}
                            />
                        </IconButton>
                    </Conditional>
                </Stack>
            </Stack>
        </SnackbarContent>
    );
});
