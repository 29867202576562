import {
    Pathfinder,
    Schema,
    openApiResolver,
    storage,
} from '@kode-frontend/pathfinder-web';
import { ReactElement, ReactNode } from 'react';

import mainSpec from '@shared/api/api.json';
import { IS_DEV } from '@shared/config/constants';

type TProps = {
    children: ReactNode;
};

export const PathfinderProvider = ({ children }: TProps) => {
    return (
        <Pathfinder
            dataKey={`yavitrina-pathfinder`}
            resolver={openApiResolver}
            storage={storage}
            active={IS_DEV}
            defaultSpecs={[mainSpec as Schema]}>
            {children as ReactElement}
        </Pathfinder>
    );
};
