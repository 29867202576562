import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Stack, Typography } from '@mui/material';
import { CustomContentProps, SnackbarContent } from 'notistack';
import { forwardRef } from 'react';

import { selectors } from '@shared/test/selectors';

type TProps = CustomContentProps & {
    description: string;
};

export const ErrorSnackbar = forwardRef<HTMLDivElement, TProps>(
    (props, ref) => {
        const {
            id,
            message: title,
            description,
            anchorOrigin,
            autoHideDuration,
            hideIconVariant,
            iconVariant,
            persist,
            ...other
        } = props;

        return (
            <SnackbarContent ref={ref} role="alert" {...other}>
                <Stack
                    direction="row"
                    gap="8px"
                    padding="12px 20px"
                    borderRadius="12px"
                    data-testid={selectors.errorSnackbarContainer}
                    sx={theme => ({ background: theme.palette.icon.error })}>
                    <ErrorOutlineIcon
                        sx={theme => ({
                            color: theme.palette.icon.onlyWhite,
                            fontSize: '28px',
                        })}
                    />
                    <Stack direction="column" gap="4px">
                        <Typography
                            variant="h4"
                            color={theme =>
                                theme.palette.typography.primaryInvert
                            }>
                            {title}
                        </Typography>
                        <Typography
                            variant="caption1"
                            color={theme =>
                                theme.palette.typography.primaryInvert
                            }>
                            {description}
                        </Typography>
                    </Stack>
                </Stack>
            </SnackbarContent>
        );
    },
);
