/* eslint-disable boundaries/element-types */
//TODO (@OKA) refactor keys
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { userKeys } from '@entities/user/model';
import { UnknownBusinessError, productApi } from '@shared/api';
import {
    PartTypeGroupsResponse,
    ProductsApiPostV2ProductRequest,
} from '@shared/api/axios-client';

import { productsKeys } from '../query-keys';

type TBusinessError = UnknownBusinessError;

type TArgs = {
    body: ProductsApiPostV2ProductRequest;
    onSuccess?: (data: PartTypeGroupsResponse) => void;
    onError?: (businessError: TBusinessError) => void;
};

export const useGetProductsV2 = ({ body, onSuccess, onError }: TArgs) => {
    const queryClient = useQueryClient();
    return useQuery(
        productsKeys.list(body).queryKey,
        () => {
            return productApi.postV2Product(body, {
                headers: {
                    Authorization: 'Bearer',
                },
            });
        },
        {
            enabled: Boolean(body),
            onSuccess: data => {
                if (data.data.providersError?.length) {
                    queryClient.invalidateQueries(userKeys.providers.queryKey);
                }
                onSuccess && onSuccess(data.data);
            },
            onError: ({ response }: AxiosError<TBusinessError>) => {
                if (!onError) {
                    return;
                }
                if (response?.data.code && response.data.message) {
                    onError(response.data);
                } else {
                    onError({
                        code: 'unknown_code',
                        message: 'что-то пошло не так',
                        response,
                    });
                }
            },
        },
    );
};
