import { Components, Theme } from '@mui/material';

import { LinkBehavior } from './link-behavior';

export const MuiButtonBase: Components<
    Omit<Theme, 'components'>
>['MuiButtonBase'] = {
    defaultProps: {
        disableRipple: true,
        LinkComponent: LinkBehavior,
    },
};
