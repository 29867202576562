import { TFormProvider } from '@entities/providers/types';
import { ProductInfo } from '@shared/api/axios-client';

export const mapProviders = (products?: ProductInfo[]): TFormProvider[] => {
    if (!products) {
        return [];
    }
    return products.map(product => ({
        id: product.warehouse.shortName || '',
        label: product.warehouse.shortName || '',
    }));
};
