import { Stack, Typography } from '@mui/material';

import { TNews } from '@entities/news/types';
import { selectors } from '@shared/test/selectors';

import { NewsCard } from '../news-card';

type TProps = {
    news: TNews;
    onBannerClick: (bannerId: string) => void;
};

export const NewsSection = ({ news, onBannerClick }: TProps) => {
    if (news.banners.length === 0) {
        return null;
    }
    return (
        <Stack
            direction="column"
            gap="40px"
            data-testid={selectors.newsSection}>
            <Typography
                variant="h3"
                sx={{
                    wordBreak: 'break-word',
                    display: '-webkit-box',
                    '-webkit-line-clamp': '2',
                    '-webkit-box-orient': 'vertical',
                    overflow: 'hidden',
                    'text-overflow': 'ellipsis',
                }}>
                {news.title}
            </Typography>
            <Stack direction="row" gap="20px" flexWrap="wrap">
                {news.banners.map(banner => (
                    <NewsCard
                        key={banner.id}
                        title={banner.title}
                        description={banner.shortText}
                        imgUrl={banner.pictureUrl}
                        onClick={() => onBannerClick(banner.id)}
                    />
                ))}
            </Stack>
        </Stack>
    );
};
