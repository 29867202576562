import { useTheme } from '@mui/material';
import { useUnit } from 'effector-react';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import { useAddClickEcommerce, useGetAdvert } from '@entities/adds/model';
import { mapAdds, mapEcommerceAddPromo } from '@entities/adds/utils/mappers';
import { $userInfo } from '@entities/auth/model/auth-domain';
import { useChangeCartAmount, useGetCart } from '@entities/cart/model';
import { updateCartAmount } from '@entities/cart/model/cart-domain';
import { TCartValue } from '@entities/cart/types';
import {
    useAddToFavorite,
    useDeleteFavorite,
    useGetFavorite,
} from '@entities/favorite/model';
import { useGetProductsV2 } from '@entities/products/model';
import { TProductFilters, TSortableField } from '@entities/products/types';
import { ecommerceProductMap } from '@entities/products/utils';
import {
    $providersFilters,
    updateProvidersFilters,
} from '@entities/providers/model/provider-domain';
import {
    $searchHistory,
    addToSearchHistory,
} from '@entities/user/model/user-domain';
import { FeatureToggle } from '@features/feature-toggle';
import { FormSearchHeaderProvider } from '@features/form-search-header';
import { ProductInfo } from '@shared/api/axios-client';
import { ENV } from '@shared/config/constants';
import {
    useNavigateExternal,
    useQueryParams,
    useYandexEcommerce,
    useYandexReachGoal,
} from '@shared/hooks';
import { CatalogPageTemplate } from '@shared/ui/templates/catalog-page-template';
import { CatalogFiltersWidgetConnector } from '@widgets/catalog-filters';
import { HeaderWidgetConnector, THeaderPaths } from '@widgets/header';

import { CatalogPage } from './catalog-page';
import {
    mapCart,
    mapFavorite,
    mapFilters,
    mapProviders,
    productMap,
} from './utils/mappers';
import { catalogPaths } from '../paths';

type TProps = {
    externalRoutes: {
        accountProviderPath: string;
        providerHomePath: string;
    };
    headerPaths: THeaderPaths;
};

const IS_YANDEX_PRODUCTS_ENABLED =
    FeatureToggle.hasFeatureFlag('yandexProducts');
const IS_PROVIDERS_ENABLED = FeatureToggle.hasFeatureFlag('providers');
const PAGE_LIMIT = 10000;
const PRODUCT_PER_PAGE = 40;

export type TFavoriteItem = {
    productId: string;
    brand: string;
    vendorCode: string;
};

export const CatalogPageConnector = ({
    externalRoutes,
    headerPaths,
}: TProps) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const userInfo = useUnit($userInfo);
    const { enqueueSnackbar } = useSnackbar();
    const navigateExt = useNavigateExternal();
    const fireProductEvent = useYandexReachGoal();
    const { params, setParams } = useQueryParams();
    const {
        handelChangeQuantity: onChangeCartAmount,
        isLoading: isAddCartLoading,
    } = useChangeCartAmount();
    const { impressionAction, promoViewAction } = useYandexEcommerce();
    const onAddClick = useAddClickEcommerce();
    const searchHistory = useUnit($searchHistory);
    const providersFilter = useUnit($providersFilters);

    const search = params.get('search');
    const brand = params.get('brand');

    const [filters, setFilters] = useState<TProductFilters | undefined>(
        undefined,
    );
    const [sortableFiled, setSortableFiled] = useState<TSortableField>({
        sortBy: 'Price',
        sortOperator: 'Asc',
    });

    const getErrorMessage = useCallback(
        () =>
            enqueueSnackbar('что-то пошло не так', {
                variant: 'error',
                description: 'обновите страницу или попробуйте позже',
            }),
        [enqueueSnackbar],
    );
    const onUnknownPostError = useCallback(
        () =>
            enqueueSnackbar('что-то пошло не так', {
                variant: 'error',
                description: 'попробуйте повторить запрос',
            }),
        [enqueueSnackbar],
    );
    const { data: addsData } = useGetAdvert({
        body: { pageType: 'searchResult' },
    });
    const adds = useMemo(
        () => mapAdds(addsData?.data.advertising_banners),
        [addsData],
    );
    useEffect(() => {
        if (addsData) {
            promoViewAction(
                mapEcommerceAddPromo(
                    addsData.data.advertising_banners,
                    String(userInfo?.clientId) || '',
                    'results',
                ),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addsData, userInfo?.clientId]);

    const { data, isLoading, isRefetching } = useGetProductsV2({
        body: {
            searchProduct: {
                article: search || '',
                attributeSort: {
                    sortBy: sortableFiled.sortBy,
                    sortOperator: sortableFiled.sortOperator,
                },
                attributeFilter: mapFilters(filters),
                brand: brand || '',
                limit: PAGE_LIMIT,
                page: 1,
            },
        },
        onSuccess: data => {
            const reducedData = data?.partTypeGroup[0]?.products.reduce<
                ProductInfo[]
            >(
                (acc, product) =>
                    acc.find(
                        item =>
                            item.warehouse.shortName ===
                            product.warehouse.shortName,
                    )
                        ? acc
                        : [...acc, product],
                [],
            );
            updateProvidersFilters({
                brand: brand || '',
                article: search || '',
                providers: mapProviders(reducedData),
            });
        },
        onError: getErrorMessage,
    });

    const { data: favoriteData } = useGetFavorite({
        onError: getErrorMessage,
    });

    const { addToFavorite } = useAddToFavorite({
        onSuccess: () =>
            enqueueSnackbar('Добавлен в избранное', {
                variant: 'info',
                closable: true,
                withIcon: false,
            }),
        onError: onUnknownPostError,
    });

    const { deleteFavorite } = useDeleteFavorite({
        onSuccess: () =>
            enqueueSnackbar('Удален из избранного', {
                variant: 'info',
                closable: true,
                withIcon: false,
            }),
        onError: onUnknownPostError,
    });

    const { data: cartData } = useGetCart({
        onSuccess: data => updateCartAmount(data.data.totalQuantity),
        onError: getErrorMessage,
    });
    const productsData = useMemo(
        () => data?.data.partTypeGroup?.[0]?.products || [],
        [data?.data.partTypeGroup],
    );
    const priorProductsData = useMemo(
        () => data?.data.partTypeGroup?.[0]?.productsY || [],
        [data?.data.partTypeGroup],
    );

    useEffect(() => {
        data?.data.providersError?.find(error => error.errorCode === '500') &&
            getErrorMessage();
    }, [data?.data.providersError, enqueueSnackbar, getErrorMessage]);

    const handelChangeQuantity = useCallback(
        (cart: TCartValue) => {
            onChangeCartAmount(
                cart,
                String(userInfo?.clientId),
                cartData?.data.cart,
            );
        },
        [cartData?.data.cart, onChangeCartAmount, userInfo?.clientId],
    );

    const handleAddFavorite = useCallback(
        (productId: string) => {
            addToFavorite({ productId });
        },
        [addToFavorite],
    );

    const handleAddProvider = useCallback(() => {
        navigate(externalRoutes.providerHomePath);
    }, [externalRoutes.providerHomePath, navigate]);

    const handleChangeProviderClick = useCallback(() => {
        navigate(externalRoutes.accountProviderPath);
    }, [externalRoutes.accountProviderPath, navigate]);

    const handlePropositionProvider = useCallback(() => {
        navigateExt(ENV.yandexPropositionForm);
    }, [navigateExt]);

    const handleSupportClick = useCallback(() => {
        navigateExt(ENV.yandexSupportLink);
    }, [navigateExt]);

    const handleHeaderSearch = useCallback(
        (search: string) => {
            addToSearchHistory(search);
            setParams({ search: search });
        },
        [setParams],
    );

    const handleAddClick = useCallback(
        (id: string) => {
            if (!addsData) {
                return;
            }
            onAddClick({
                targetAddId: id,
                adds: addsData.data.advertising_banners,
                userId: String(userInfo?.clientId) || '',
                position: 'results',
            });
        },
        [addsData, onAddClick, userInfo?.clientId],
    );

    const handleChangeSort = useCallback(
        (sort: TSortableField) => {
            switch (sort.sortBy) {
                case 'Alphabet':
                    fireProductEvent('sortByName', { sort });
                    break;
                case 'DeliveryDate':
                    fireProductEvent('sortByDeliveryDate', { sort });
                    break;
                case 'Price':
                    fireProductEvent('sortByPrice', { sort });
                    break;
                default:
                    break;
            }

            setSortableFiled(sort);
        },
        [fireProductEvent],
    );

    const favorites = useMemo(
        () => mapFavorite(favoriteData?.data.productsFavorites),
        [favoriteData?.data.productsFavorites],
    );

    const favoriteItem = favorites.find(
        favorite => favorite.brand === brand && favorite.vendorCode === search,
    );

    const handleRemoveFavorite = useCallback(() => {
        deleteFavorite(favoriteItem?.productId || '');
    }, [deleteFavorite, favoriteItem]);

    const dataProducts = data?.data.partTypeGroup?.[0]?.products;

    const dataPriorProducts = useMemo(
        () => (IS_YANDEX_PRODUCTS_ENABLED ? priorProductsData : []),
        [priorProductsData],
    );

    const products = useMemo(() => productMap(dataProducts), [dataProducts]);
    const priorProducts = useMemo(
        () => productMap(dataPriorProducts, true),
        [dataPriorProducts],
    );

    useEffect(() => {
        impressionAction(ecommerceProductMap(productsData || []));
    }, [impressionAction, productsData]);

    const providersError = useMemo(
        () =>
            data?.data.providersError
                ?.filter(error => error.errorCode === '401')
                .map(error => error.providerName) || [],
        [data?.data.providersError],
    );

    const cartValue = useMemo(
        () => mapCart(cartData?.data.cart),
        [cartData?.data.cart],
    );
    if (!brand) {
        return (
            <Navigate
                to={`${catalogPaths.brand.absolute}?search=${search}`}
                replace
            />
        );
    }

    return (
        <CatalogPageTemplate
            color="secondary"
            Header={
                <HeaderWidgetConnector
                    paths={headerPaths}
                    withLogo
                    variant="primary"
                    withProfile
                    backgroundColor={theme.palette.surface.secondary}>
                    <FormSearchHeaderProvider
                        options={searchHistory}
                        initSearch={search || ''}
                        onSubmit={data => handleHeaderSearch(data.search)}
                    />
                </HeaderWidgetConnector>
            }>
            <CatalogPage
                adds={adds}
                pageLimit={PRODUCT_PER_PAGE}
                totalCount={data?.data.partTypeGroup?.[0]?.totalCount || 0}
                products={products}
                priorProducts={priorProducts}
                sort={sortableFiled}
                cartValue={cartValue}
                article={search || ''}
                providersError={providersError}
                isFiltered={Boolean(filters)}
                isFavorite={Boolean(favoriteItem)}
                isLoading={isLoading || isRefetching}
                isProducts={
                    Boolean(productsData?.length) ||
                    Boolean(priorProducts.length)
                }
                isAddCartLoading={isAddCartLoading}
                FilterWidgetComponent={
                    <CatalogFiltersWidgetConnector
                        products={[...productsData, ...priorProductsData]}
                        providers={providersFilter.providers}
                        onFilterChange={setFilters}
                    />
                }
                favoriteHandlers={{
                    onAddFavorite: handleAddFavorite,
                    onRemoveFavorite: handleRemoveFavorite,
                }}
                onSortChange={handleChangeSort}
                onHelpClick={handleSupportClick}
                onAddProvider={
                    IS_PROVIDERS_ENABLED ? handleAddProvider : undefined
                }
                onQuantityChange={handelChangeQuantity}
                onChangeProvider={
                    IS_PROVIDERS_ENABLED ? handleChangeProviderClick : undefined
                }
                onPropositionProvider={handlePropositionProvider}
                onAddClick={handleAddClick}
                onBackClick={() => navigate(-1)}
            />
        </CatalogPageTemplate>
    );
};
