import { selectors } from '@shared/test/selectors';
import { CenteredModal } from '@shared/ui/molecules/centered-modal';

import { NewsCardDetails } from '../news-card-details';

type TProps = {
    open: boolean;
    title: string;
    imgUrl: string;
    buttonText: string;
    text: string;
    onClick: () => void;
    onClose: () => void;
};

export const NewsModal = ({
    open,
    title,
    imgUrl,
    buttonText,
    text,
    onClick,
    onClose,
}: TProps) => {
    return (
        <CenteredModal
            open={open}
            onClose={onClose}
            dataTestId={selectors.authCompanyAuthModal}>
            <NewsCardDetails
                title={title}
                imgUrl={imgUrl}
                text={text}
                buttonText={buttonText}
                onClick={onClick}
                onClose={onClose}
            />
        </CenteredModal>
    );
};
