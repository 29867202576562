import { TProductTable } from '@entities/products/types';
import { makeField } from '@entities/products/utils/make-product-table-field';
import { SearchBrandsResponse } from '@shared/api/axios-client';

export const mapProduct = (
    brandsData?: SearchBrandsResponse,
): TProductTable[] => {
    if (!brandsData) {
        return [];
    }
    return brandsData.brands.map((brand, index) => {
        const currentNumber = index + 1;
        const stringNumber =
            currentNumber >= 10
                ? String(currentNumber)
                : `${0}${currentNumber}`;
        return {
            id: brand || '',
            fields: [
                makeField(stringNumber, 'left', 1, true),
                makeField(brand, 'left', 6),
                makeField(brandsData.article, 'left', 4),
            ],
        };
    });
};
