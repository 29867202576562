import {
    differenceInDays,
    differenceInMonths,
    differenceInYears,
    isSameMonth,
    isSameYear,
} from 'date-fns';

import { pluralize } from '../../number';

export const formatDistance = (startDate: Date, endDate: Date): string => {
    const years = differenceInYears(endDate, startDate);
    const months = differenceInMonths(endDate, startDate) % 12;
    const days = differenceInDays(endDate, startDate);

    const pluralizeDays = pluralize(days, ['день', 'дня', 'дней'], 'сегодня');
    const pluralizeMonths = pluralize(months, ['месяц', 'месяца', 'месяцев']);
    const pluralizeYears = pluralize(years, ['год', 'года', 'лет']);

    const sameYear = isSameYear(startDate, endDate);
    const sameMonth = isSameMonth(startDate, endDate);

    if (sameYear && sameMonth) {
        return pluralizeDays;
    }

    if (sameYear && !sameMonth) {
        return pluralizeMonths;
    }

    return `${pluralizeYears} ${pluralizeMonths}`;
};
