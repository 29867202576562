import { Components, Theme } from '@mui/material';

export const MuiInput: Components<Omit<Theme, 'components'>>['MuiInput'] = {
    defaultProps: {
        disableUnderline: true,
    },
    styleOverrides: {
        root: ({ theme }) => ({
            fontFamily: theme.typography.inputValue1.fontFamily,
            fontSize: theme.typography.inputValue1.fontSize,
            fontWeight: theme.typography.inputValue1.fontWeight,
            lineHeight: theme.typography.inputValue1.lineHeight,
            border: 'none',
            paddingBottom: '10px',
            minHeight: '56px',
            color: theme.palette.typography.primary,
            borderBottom: `1px solid ${theme.palette.border.border3}`,
            backgroundColor: 'transparent',
            '&.Mui-disabled': {
                color: theme.palette.text.disabled,
                borderColor: theme.palette.text.disabled,
            },
            '&.Mui-focused': {
                backgroundColor: 'transparent',
                borderBottom: `1px solid ${theme.palette.border.border1}`,
            },
            '&:hover': {
                borderBottom: `1px solid ${theme.palette.border.border1}`,
            },
            '&.Mui-error': {
                color: theme.palette.typography.negative,
                borderBottom: `1px solid ${theme.palette.typography.negative}`,
            },
            '.MuiAutocomplete-endAdornment': {
                top: 'auto',
            },
            '&.MuiInputBase-sizeSmall': {
                paddingBottom: '0',
                height: '40px',
                minHeight: '40px',
            },
        }),
        input: ({ theme }) => ({
            '&.MuiInputBase-inputSizeSmall': {
                fontFamily: theme.typography.inputValue3.fontFamily,
                fontSize: theme.typography.inputValue3.fontSize,
                fontWeight: theme.typography.inputValue3.fontWeight,
                lineHeight: theme.typography.inputValue3.lineHeight,
                paddingBottom: '0',
                height: '40px',
            },
            '&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active':
                {
                    boxShadow: `inset 0 0 0 50px #fff !important;`,
                },
        }),
        multiline: {
            height: 'auto',
        },
    },
};
