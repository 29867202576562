import { forwardRef } from 'react';
import {
    Link as RouterLink,
    LinkProps as RouterLinkProps,
} from 'react-router-dom';

export const LinkBehavior = forwardRef<
    HTMLAnchorElement,
    Omit<RouterLinkProps, 'to'> & {
        href: RouterLinkProps['to'];
    }
>((props, ref) => {
    const { href, ...other } = props;
    const stringLink = typeof href === 'string' ? href : href.pathname;
    const isExternal = /^(http|https):\/\//.test(stringLink || '');
    return (
        <RouterLink
            ref={ref}
            to={href}
            {...other}
            target={isExternal ? '_blank' : undefined}
        />
    );
});
