import { createTheme, responsiveFontSizes } from '@mui/material';

import {
    MuiAccordion,
    MuiAccordionDetails,
    MuiAccordionSummary,
    MuiAutocomplete,
    MuiBadge,
    MuiButton,
    MuiButtonBase,
    MuiCheckbox,
    MuiChip,
    MuiDialogActions,
    MuiDialogContent,
    MuiDialogTitle,
    MuiFormHelperText,
    MuiIconButton,
    MuiInput,
    MuiInputLabel,
    MuiLink,
    MuiList,
    MuiMenu,
    MuiMenuItem,
    MuiRadio,
    MuiSelect,
    MuiTabPanel,
    MuiTabs,
    MuiTooltip,
    typography,
} from './light';

const MuiLightTheme = createTheme({
    palette: {
        bg: {
            primary: '#ffffff',
            primaryInvert: '#1C1C1E',
        },
        surface: {
            primary: '#F2F2F7',
            primaryInvert: '#2C2C2E',
            secondary: '#F5F5F7',
            errorLight: '#FFEEEE',
        },
        typography: {
            primary: '#1C1C1E',
            primaryInvert: '#ffffff',
            secondary: '#969699',
            secondaryInvert: '#969699',
            tertiary: '#8E8E90',
            tertiaryInvert: '#49494B',
            quaternary: '#AEAEB2',
            quaternaryInvert: '#49494B',
            onlyWhite: '#ffffff',
            onlyDark: '#1C1C1E',
            negative: '#FF1F1F',
            positive: '#7DDF64',
        },
        icon: {
            primary: '#1C1C1E',
            primaryInvert: '#ffffff',
            secondary: '#969699',
            secondaryInvert: '#969699',
            tertiary: '#8E8E90',
            tertiaryInvert: '#49494B',
            quaternary: '#AEAEB2',
            onlyWhite: '#ffffff',
            onlyDark: '#1C1C1E',
            error: '#FF5050',
            positive: '#7DDF64',
        },
        other: {
            tooltip: '#49494B',
            backdrop: '#1C1C1ECC',
        },
        border: {
            border1: '#1C1C1E',
            border2: '#8E8E90',
            border3: '#AEAEB2',
            border4: '#E5E5E9',
            border5: '#C8C8D0',
        },
        error: {
            main: '#FF5050',
        },
        buttonPrimary: {
            main: '#1C1C1E',
            dark: '#49494B',
            light: '#6E6E70',
            contrastText: '#FFFFFF',
        },
        buttonPrimaryInvert: {
            main: '#FFFFFF',
            dark: '#AEAEB2',
            light: '#F2F2F7',
            contrastText: '#1C1C1E',
        },
        buttonSecondaryEnabled: {
            main: '#F2F2F7',
            dark: '#E5E5E9',
            light: '#C8C8D0',
            contrastText: '#1C1C1E',
        },
        buttonSecondaryText: {
            main: '#6E6E70',
            dark: '#49494B',
            light: '#8E8E90',
        },
        buttonInvertSecondaryEnabled: {
            main: '#3A3A3C',
            dark: '#49494B',
            light: '#2C2C2E',
            contrastText: '#FFFFFF',
        },
        buttonInvertSecondaryText: {
            main: '#8E8E90',
            dark: '#AEAEB2',
            light: '#E5E5E9',
        },
        button: {
            buttonPrimaryState: {
                border: '#000000',
                disabledBackground: '#1C1C1E14',
                disabled: '#1C1C1E52',
            },
            buttonSecondaryEnabledState: {
                border: '#000000',
                disabled: '#1C1C1E52',
                disabledBackground: '#1C1C1E14',
            },
            buttonSecondaryTextState: {
                border: '#6E6E70',
                disabled: '#1C1C1E52',
                disabledBackground: 'transparent',
            },
            buttonPrimaryInvertState: {
                border: '#FFFFFF',
                disabled: '#FFFFFF3D',
                disabledBackground: '#FFFFFF14',
            },
            buttonInvertSecondaryEnabledState: {
                border: '#FFFFFF',
                disabled: '#FFFFFF3D',
                disabledBackground: '#FFFFFF14',
            },
            buttonInvertSecondaryTextState: {
                border: '#F5F5F7',
                disabled: '#FFFFFF3D',
                disabledBackground: 'transparent',
            },
        },
    },
    components: {
        MuiButtonBase,
        MuiButton,
        MuiInput,
        MuiInputLabel,
        MuiFormHelperText,
        MuiMenuItem,
        MuiLink,
        MuiSelect,
        MuiDialogTitle,
        MuiDialogContent,
        MuiDialogActions,
        MuiChip,
        MuiBadge,
        MuiTooltip,
        MuiList,
        MuiMenu,
        MuiCheckbox,
        MuiTabs,
        MuiTabPanel,
        MuiAccordion,
        MuiAccordionSummary,
        MuiAccordionDetails,
        MuiIconButton,
        MuiAutocomplete,
        MuiRadio,
    },
    typography,
});

export const lightTheme = responsiveFontSizes(MuiLightTheme);
