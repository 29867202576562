import { SvgIconProps } from '@mui/material';

export const ErrorOutlinedIcon = (props: SvgIconProps) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 111 106"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path
                d="M7.12155 2.87866L108.122 103.879M2.87891 103.879L103.879 2.87866"
                stroke="currentColor"
                stroke-width="6"
            />
        </svg>
    );
};
