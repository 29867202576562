import { SVGProps } from 'react';

export const DeleteIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg
            {...props}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM16.8434 18L12.0001 13.1567L7.15678 18L6.00016 16.8434L10.8434 12.0001L6 7.15662L7.15662 6L12.0001 10.8434L16.8435 6.00001L18.0001 7.15664L13.1567 12.0001L18 16.8434L16.8434 18Z"
                fill="currentColor"
            />
        </svg>
    );
};
