export const DoneIcon = () => {
    return (
        <svg
            width="128"
            height="128"
            viewBox="0 0 128 128"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M127 21.3737L38.4827 110L0 71.4699L3.36951 68.0962L38.4827 103.253L123.631 18L127 21.3737Z"
                fill="currentColor"
            />
        </svg>
    );
};
