export enum DefaultFlags {
    favorite = 'favorite',
    newses = 'newses',
    adds = 'adds',
    yandexProducts = 'yandexProducts',
    providers = 'providers',
    priceList = 'priceList',
}

export type TFlags = keyof typeof DefaultFlags;

export type TFeatureFlags = {
    [key in TFlags]: boolean;
};

export type TSetterFn = (values: TFeatureFlags) => void;
export type TGetterFn = () => TFeatureFlags;
export type THasFn = (name: TFlags) => boolean;
export type TChangeFn = (name: TFlags, value: boolean) => void;

export type TFeatureToggle = {
    setFeatureFlags: TSetterFn;
    getFeatureFlags: TGetterFn;
    hasFeatureFlag: THasFn;
    changeFeatureFlag: TChangeFn;
};

export { nameFlags } from './name-flags';
